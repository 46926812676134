/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link';
import moment from 'moment-timezone';
import {
  Grid,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  MonetizationOnOutlined as MonetizationOnOutlinedIcon,
  Done as DoneIcon,
} from '@mui/icons-material';
import { connect } from 'react-redux';

import ClientIcons from '../ClientIcons';
import Tooltip from '../Tooltip';
import { formatTime } from '../../../../shared_client_utils/dateUtils';
import calendarEventTypes from '../../configs/calendarEventTypes';
import { clientPath } from '../../../../client_routes';

const useStyles = makeStyles()((theme) => ({
  appointmentRoot: {
    flexDirection: 'column',
    flexWrap: 'initial',
    padding: theme.spacing(1 / 2),
    height: '100%',
    overflow: 'hidden',
    borderLeft: '4px solid',
  },
  busyTimeRoot: {
    flexDirection: 'column',
    padding: theme.spacing(1 / 2),
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  headerLeft: {
    alignItems: 'center',
    width: 'auto',
    marginRight: theme.spacing(1 / 2),
    fontSize: theme.spacing(13 / 8),
    fontWeight: 800,
    textTransform: 'lowercase',

    '& > div': {
      whiteSpace: 'nowrap',
    },
  },
  startTime: {
    marginRight: theme.spacing(1 / 2),
  },
  timeDelimiter: {
    alignItems: 'center',
    width: 'auto',
    marginRight: theme.spacing(1 / 2),
  },
  headerRight: {
    justifyContent: 'flex-end',
    width: 'auto',
    fontSize: theme.spacing(2),
    fill: theme.palette.primary.main,
  },
  appointmentStatusIcons: {
    width: 'auto',
  },
  busyBox: {
    width: 'auto',
    paddingRight: theme.spacing(1 / 4),
    fontSize: theme.spacing(7 / 4),
    color: theme.palette.grey[800],
  },
  content: {
    flexDirection: 'column',
    marginTop: theme.spacing(),
    fontSize: theme.spacing(7 / 4),
    '&.isShort': {
      marginTop: 0
    }
  },
  contentItem: {
    marginTop: theme.spacing(),

    '&:first-of-type': {
      marginTop: 0,
    },
  },
  icon: {
    fontSize: 'inherit',
    fill: 'inherit',
    marginLeft: '0.1em',
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.50)',
    boxShadow: theme.shadows[2],
    fontSize: '14px',
    maxWidth: 'max-content',
  },
  clientNameLink: {
    color: 'inherit',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  busyStepsContainer: {
    display: 'flex',
    flex: 'auto',
    height: '100%',
    width: 'calc(100% + 10px)',
    flexFlow: 'column nowrap',
    position: 'absolute',
    borderLeft: '1px solid #dddddd',
    cursor: 'initial',
  },
  busyStepItem: {
    backgroundColor: '#e7e8ea',
    borderTop: '1px solid #f7f7f7',
    boxSizing: 'inherit',
    flex: '1 0'
  }
}));

const createArray = (length) => Array.from({ length }, (_, i) => i + 1);

const AppointmentEvent = (props) => {
  const {
    classes,
    title,
    style,
    isShort,
    cx,
    event: {
      serviceName,
      clientFlags,
      clientNoShows,
      isPaid,
      isCompleted,
      clientId,
      isClientNew,
      leftNote,
      notes,
      ...event
    },
  } = props;

  const start = formatTime(event.start);
  const end = formatTime(event.end);

  const handleRootClick = (event) => {
    if (event.target.tagName === 'A') {
      event.stopPropagation();
    }
  };

  return (
    <Grid
      container
      style={style}
      data-testid={`appointmentEvent-${event.id}`}
      className={classes.appointmentRoot}
      onClick={handleRootClick}
    >
      <Grid container className={classes.header}>
        <Grid container className={classes.headerLeft} data-testclass="appointmentTime">
          <Grid item className={classes.startTime}>{start}</Grid>
          <Grid item className={classes.timeDelimiter}>-</Grid>
          <Grid item>{end}</Grid>
        </Grid>

        <Grid container className={classes.headerRight}>
          <ClientIcons
            disableNoShowsNumber
            isClientNew={isClientNew}
            flags={clientFlags}
            noShows={clientNoShows}
            leftNote={leftNote}
            name={event.title}
            notes={notes}
          />

          <Grid container className={classes.appointmentStatusIcons}>
            {isPaid && (
              <Tooltip title="Paid">
                <MonetizationOnOutlinedIcon className={classes.icon} />
              </Tooltip>
            )}

            {isCompleted && (
              <Tooltip title="Completed">
                <DoneIcon className={classes.icon} />
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={cx(classes.content, {isShort})}>
        <Grid item className={classes.contentItem}>
          {isShort ? (
            <span>{title}</span>
          ) : (
            <Link href={clientPath(clientId)}>
              <a className={classes.clientNameLink} data-testclass="appointmentClientLink">
                {title}
              </a>
            </Link>
          )}
        </Grid>
        {!isShort && (
          <Grid item className={classes.contentItem}>
            {serviceName}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const BusyTimeEvent = (props) => {
  const {
    classes,
    style = {},
    isShort,
    currentStaff,
    isAllRostered,
    slotStep,
    // isDay,
    event: {
      description,
      duration,
      ...event
    },
  } = props;

  const { isOwner, id: currentStaffId, access = []} = currentStaff;
  const isAllow = isOwner || access.includes('staffBusyTimeAccess') || currentStaffId === event.staffId;

  const start = formatTime(event.start);
  let end = formatTime(event.end);
  const endOfDay = moment(event.start).endOf('day').toDate();
  if (event?.end && event.end.valueOf() > endOfDay.valueOf()) {
    const endTime = moment().endOf('day').subtract(599, 'seconds').toDate()
    end = formatTime(endTime);
  }

  if (!isAllow && !isAllRostered) {
    let newArr = [];
    if (duration > 0) {
      const itemsCount = Math.round((Math.ceil(duration / 10) * 10) / slotStep);
      newArr = createArray(itemsCount);
    }
    const content = newArr.map(arrItem => (<Grid key={arrItem} item className={classes.busyStepItem}>&nbsp;</Grid>))
    return (
      <Grid container className={classes.busyStepsContainer}>
        {content}
      </Grid>
    )
  }

  return (
    <Grid container className={classes.busyTimeRoot} style={{...style, ...(!isAllow && isAllRostered ? {backgroundColor: '#ececec'} : {})}}>
      <Grid container className={classes.header}>
        <Grid container className={classes.headerLeft}>
          {isAllow ? (
            <Grid item className={classes.startTime}>{start}</Grid>
          ) : (
            <Grid item className={classes.startTime}>&nbsp;</Grid>
          )}
          <Grid item className={classes.timeDelimiter}>{isAllow ? '-' : ''}</Grid>
          <Grid item>{isAllow ? end : ''}</Grid>
        </Grid>
        <Grid container className={classes.headerRight}>
          <Grid item className={classes.busyBox}>
            <i>{isAllow ? 'busy' : ''}</i>
          </Grid>
        </Grid>
      </Grid>
      {!isShort && (
        <Grid container className={classes.content}>
          <Grid item className={classes.contentItem}>
            {isAllow ? description : ''}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const componentsMap = {
  [calendarEventTypes.appointment]: AppointmentEvent,
  [calendarEventTypes.busyTime]: BusyTimeEvent,
};

const Event = (props) => {
  const { event } = props;
  const {classes, cx} = useStyles();

  const { auth = {} } = props;
  const { currentStaff = {} } = auth;

  const Component = componentsMap[event.type];

  return (
    <Component
      {...props}
      currentStaff={currentStaff}
      cx={cx}
      classes={classes}
    />
  );
}
Event.defaultProps = {
  isShort: false
}

const mapStateToProps = ({ auth }) => ({
  auth
});

export default connect(mapStateToProps, null)(Event);
