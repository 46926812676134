/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import { Component, createRef } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Dinero from 'dinero.js';
import {
  Grid,
  Toolbar,
  Tabs,
  Tab,
  Typography,
  Hidden,
  Box
} from '@mui/material';
import { cx } from '@emotion/css'
import { withStyles } from 'tss-react/mui';
import {
  Done as DoneIcon,
} from '@mui/icons-material';

import { green } from '@mui/material/colors';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import Details from './Details';
import Notes from './Notes';
import Requirement from './Requirement';
import ServiceQuestion from './ServiceQuestion';
import { loadLocations } from '../../../../shared_slices/locationsSlice';
import { loadSalesSettings } from '../../slices/salesSettingsSlice';
import { verifyClientCreditCard, createClientCreditCard, readClientCreditCards, createClientForBooking } from '../../../../shared_slices/paymentSlice';

import {
  loadAppointment,
  createAppointmentWithExtras,
  updateAppointmentWithExtras,
  cancelAppointment,
  updateNotesOnly
} from '../../slices/appointmentsSlice';
import { createInvoiceWithAppointmentItem } from '../../slices/invoicesSlice';
import {
  getDate,
  getCurrentClientTime,
  convertDateTimeIntoDateAndTime,
  getStartAndEndTimeInBusinessTz,
} from '../../../../shared_client_utils/dateUtils';
import {
  areEventBordersIncludedInStaffWorkingHours,
  areEventBordersIncludedInLocationWorkingHours,
  filterServicesByStaff,
} from '../../utils/staffUtils';
import {
  composeOverlapByDurationErrorMessage,
  composeOverlapByPaddingsErrorMessage,
  composeOverlapByBusyTimeErrorMessage,
  composeAppointmentBusyResourceErrorMessage,
  getNoChangeAppointmentReason
} from '../../utils/appointmentUtils';
import {
  validateObjectByMap,
  makeOption,
  makeOptionWithField,
} from '../../../../shared_client_utils/formUtils';
import { clientErrorsMap } from '../../../../shared_client_utils/clientUtils';
import Invoice from '../Invoice';
import SoapNote from '../SoapNote';
import { moneytizeObject } from '../../../../shared_client_utils/moneyUtils';
import {
  BookingApi,
  ClientsApi,
  ServicesApi,
  ResourcesApi,
  SoapNotesApi,
} from '../../../../client_http_api';
import CustomDialog, {
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogActions,
} from '../../../../shared_components/CustomDialog';
import AppointmentCancellation from '../AppointmentCancellation';
import OrangeButton from '../../../../shared_components/buttons/Orange';
import GreenButton from '../../../../shared_components/buttons/Green';
import RedButton from '../../../../shared_components/buttons/Red';
import { PlusIcon } from '../../../../shared_components/icons';
import { OrangeInfoStrip } from '../../../../shared_components/InfoStrip';
import CircularProgressWithBackdrop from '../../../../shared_components/CircularProgressWithBackdrop';
import { checkServiceQuestionForCalendar } from '../../utils/serviceUtils';
import CustomPopper from '../CustomPopper';
import MobileMenu, { MenuItem } from '../MobileMenu';
import Card from '../../../../shared_components/Payment/Payment';
import { loadGetStartedStep, updateGetStartedStep } from "../../../../shared_slices/getStartedSlice";

const styles = (theme) => ({
  dialogContent: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  toolbar: {
    width: '100%',
    minHeight: 'auto',
    display: 'block',
    padding: 0,
    background: "#fcfbfc",
    boxShadow: 'unset',
  },
  tabsRoot: {
    height: '47px',
    border: '1px solid #efefef',
    borderRadius: theme.spacing(),
    margin: theme.spacing(1 / 2, 3),

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1 / 2, 2),
    },
  },
  tabsIndicator: {
    backgroundColor: 'unset',
  },
  tabRoot: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    textTransform: 'initial',
    minWidth: '72px',
    height: '43px',
  },
  tabSelected: {
    color: '#f5ac3d',
    backgroundColor: "#fff",
    borderRadius: '5px',
    boxShadow: "0px 5px 9px 1px #f5a62329",
    fontWeight: 600
  },
  main: {
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
  flexContainer: {
    display: 'inline-block',
    height: '100%',
  },
  buttonBox: {
    justifyContent: 'space-between',
  },
  leftButtons: {
    width: 'auto',
  },
  rightButtons: {
    width: 'auto',
  },
  button: {
    marginRight: theme.spacing(3),

    '&:last-of-type': {
      marginRight: 0,
    },
  },
  mobuleInvoicePlusBox: {
    alignItems: 'center',
  },
  mobilePlusIconBox: {
    width: 'auto',
    marginLeft: theme.spacing(),
  },
  plusIcon: {
    height: '16px',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      height: '12px',
    },
  },
  trashIcon: {
    width: theme.spacing(15 / 8),
    height: theme.spacing(15 / 8),
  },
  noChangeReasonBox: {
    margin: theme.spacing(0, 3, 2, 3),

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 2, 2),
    },
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  paperScrollBody: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
      maxWidth: '100% !important',
      width: '100% !important',
    },
  },
  doneIcon: {
    paddingLeft: theme.spacing(1 / 2),
    fill: theme.palette.primary.main,
  },
  invoiceItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
})

const getSessionError = (err = {}, flashMessage) => {
  if (err?.message?.includes('Session is expired')) {
    return flashMessage('Session is expired, refresh the page please', 'error')
  }
}

const tabs = [
  {
    label: 'Details',
    value: 'Details',
    component: Details,
    default: true,
  },
  {
    label: 'Notes',
    value: 'Notes',
    component: Notes,
  },
  {
    label: 'Requirement',
    value: 'Requirement',
    component: Requirement,
  },
];

const initializeClient = {
  id: '',
  firstName: '',
  lastName: '',
  mobileNumber: '',
  email: '',
};

const appointmentErrorsMap = {
  locationId: {
    isValid: (value) => value && value.length > 0,
  },
  serviceId: {
    isValid: (value) => value && value.length > 0,
  },
  staffId: {
    isValid: (value) => value && value.length > 0,
  },
  date: {
    isValid: (value) => !!value,
  },
  time: {
    isValid: (value) => value && value > 0,
  },
  resourceItemId: {
    isValid: (value, { resourceId }) => {
      return !resourceId || (value && value.length > 0);
    },
  }
};

const checkServiceBookingAnswerPresence = (appointment, selectedService) => {
  const displayServiceQuestion = checkServiceQuestionForCalendar(
    selectedService,
  );
  if (!appointment.serviceBookingQuestion && !displayServiceQuestion) {
    return true;
  }
  return appointment.serviceBookingAnswer;
};

const initializeAppointment = (payload, eventStartTime, moment) => {
  // We need to use start time from event
  // because its time already converted according to business and browser timezone
  const [date, time] = convertDateTimeIntoDateAndTime(eventStartTime, moment);

  const appointment = {
    ...payload,
    date,
    time,
  };

  return moneytizeObject(appointment, 'price');
};

const prepareAppointment = (appointment, startTime, endTime) => {
  const {
    id,
    locationId,
    serviceId,
    staffId,
    resourceItemId,
    duration,
    paddingBefore,
    paddingAfter,
    price,
    notes,
    serviceBookingQuestion,
    serviceBookingAnswer,
  } = appointment;

  return {
    id,
    price,
    duration,
    paddingBefore,
    paddingAfter,
    notes,
    serviceBookingQuestion,
    serviceBookingAnswer,
    locationId,
    serviceId,
    staffId,
    resourceItemId,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    utcOffset: startTime.utcOffset(),
  };
};

const composePaddingMessage = (before, after) => {
  let message = 'The service has';
  const beforeText = `${before} minutes padding before`;
  const afterText = `${after} minutes padding after`;

  if (before > 0 && after > 0) {
    message = `${message} ${beforeText} and ${afterText}`;
  } else if (before > 0) {
    message = `${message} ${beforeText}`;
  } else {
    message = `${message} ${afterText}`;
  }

  return message;
};

const getExtraTimeInfo = (paddingBefore, paddingAfter) => {
  if (paddingBefore === 0 && paddingAfter === 0) {
    return [false, ''];
  }

  return [
    true,
    composePaddingMessage(paddingBefore, paddingAfter),
  ];
};

const initializeBookingQuestionInAppointment = (appointment, selectedService) => {
  const question = (
    appointment.serviceBookingQuestion
    || selectedService?.bookingQuestion
  );

  return {
    ...appointment,
    serviceBookingQuestion: question,
  };
};

const updateBookingQuestionInAppointment = (appointment, selectedService) => {
  return {
    ...appointment,
    serviceBookingQuestion: selectedService?.bookingQuestion,
    serviceBookingAnswer: '',
    originalServiceBookingQuestion: appointment.serviceBookingQuestion,
    originalServiceBookingAnswer: appointment.serviceBookingAnswer,
    originalServiceId: appointment.serviceId,
  };
};

class AddAppointment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 'Details',
      appointment: {
        id: '',
        locationId: '',
        date: null,
        serviceId: '',
        staffId: '',
        resourceItemId: '',
        time: null,
        duration: 0,
        paddingBefore: 0,
        paddingAfter: 0,
        price: 0,
        currency: 'USD',
        isChangeable: true,
        notes: '',
        serviceBookingQuestion: '',
        serviceBookingAnswer: '',
      },
      stripeResponse: {},
      stripeCardVerify: {},
      isCreditCardFormDialogOpened: false,
      stripeCardAlreadyAdd: false,
      stripeError: false,
      clientAlreadyCreate: false,
      appointmentErrors: {},
      client: { ...initializeClient },
      clientErrors: {},
      isDisabledClientFields: true,
      isEditAction: false,
      isLoading: false,
      hasServiceExtraTime: false,
      serviceExtraTimeTooltipText: '',
      isServiceLineError: false,
      isServiceLineMessage: '',
      serviceLineSeverity: '',
      isCancelDialogOpened: false,
      isInvoiceDialogOpened: false,
      isSOAPNoteDialogOpened: false,
      currentInvoiceId: '',
      workingStaff: [],
      selectedStaff: null,
      services: [],
      selectedService: null,
      resourceItems: [],
      selectedResourceItem: null,
      isServiceQuestionDialogOpened: false,
      proceedSavingAction: null,
      salesSettings: {
        ...props.salesSettings,
      },
    };
    this.handleTabChange = this.handleTabChange.bind(this);

    this.creditCardHandler = this.creditCardHandler.bind(this);
    this.createClientHandler = this.createClientHandler.bind(this);
    this.createAppointmentHandler = this.createAppointmentHandler.bind(this);
    this.handleOpenCreditCardForm = this.handleOpenCreditCardForm.bind(this);
    this.handleCloseCreditCardForm = this.handleCloseCreditCardForm.bind(this);
    this.handleChangeAppointment = this.handleChangeAppointment.bind(this)
    this.handleChangeServiceRow = this.handleChangeServiceRow.bind(this);
    this.handleChangeClient = this.handleChangeClient.bind(this);
    this.handleSelectClient = this.handleSelectClient.bind(this);
    this.handleEnableClientFields = this.handleEnableClientFields.bind(this);
    this.handleChangeService = this.handleChangeService.bind(this);
    // this.handleChangeLocation = this.handleChangeLocation.bind(this);
    this.onClickSaveAppointment = this.onClickSaveAppointment.bind(this);
    this.onClickCreateAppointment = this.onClickCreateAppointment.bind(this);
    this.handleOpenCancelDialog = this.handleOpenCancelDialog.bind(this);
    this.handleCloseCancelDialog = this.handleCloseCancelDialog.bind(this);
    this.handleCancelAppointment = this.handleCancelAppointment.bind(this);
    this.handleCreateInvoice = this.handleCreateInvoice.bind(this);
    this.handleOpenEditInvoiceDialog = this.handleOpenEditInvoiceDialog.bind(this);
    this.handleCloseInvoiceDialog = this.handleCloseInvoiceDialog.bind(this);
    this.handleAppointmentCallback = this.handleAppointmentCallback.bind(this);
    this.handleOpenSOAPNoteDialog = this.handleOpenSOAPNoteDialog.bind(this);
    this.handleCloseSOAPNoteDialog = this.handleCloseSOAPNoteDialog.bind(this);
    this.handleCreateSOAPNote = this.handleCreateSOAPNote.bind(this);
    this.handleOnCloseAppointmentDialog = this.handleOnCloseAppointmentDialog.bind(this);
    this.onClickCloseServiceQuestionDialog = this.onClickCloseServiceQuestionDialog.bind(this);
    this.saveServiceBookingAnswerAndProceed = this.saveServiceBookingAnswerAndProceed.bind(this);
    this.onClickNotesUpdate = this.onClickNotesUpdate.bind(this);

    this.saveButtonAnchor = createRef();
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const {
      auth,
      staff,
      selectedEvent,
      clickedSlotDate,
      loadAppointment,
      selectedLocation,
      clickedSlotStaffId,
      handleDisplayFlashMessage,
      business: { timezone },
      loadSalesSettings,
    } = this.props;
    const { payload: salesSettings } = await loadSalesSettings();
    this.setState({ salesSettings });

    try {
      const workingStaff = staff.map(
        makeOptionWithField({ labelColumn: 'fullName' }),
      );
      const selectedStaff = workingStaff.find(({ id }) => {
        return id === clickedSlotStaffId;
      });
      if (!selectedStaff) {
        return;
      }

      if (!selectedEvent) {
        const [date, time] = convertDateTimeIntoDateAndTime(
          clickedSlotDate,
          moment,
        );

        const servicesPayload = await ServicesApi.fetchServicesForAppointment(
          {
            timezone,
            locationId: selectedLocation.id,
            staffId: selectedStaff.id,
            startTime: date.toISOString(),
            date: getDate(date),
          },
          auth,
        );
        const services = filterServicesByStaff(
          servicesPayload.map(makeOption),
          selectedStaff,
        );

        this.setState(({ appointment }) => ({
          workingStaff,
          selectedStaff,
          services,
          appointment: {
            ...appointment,
            date,
            time,
            locationId: selectedLocation.id,
            staffId: clickedSlotStaffId,
          },
          isLoading: false,
        }));
        return;
      }

      const { id, start } = selectedEvent;

      const { payload } = await loadAppointment(id);
      let appointment = initializeAppointment(payload, start, moment);

      const outstandingBalance = await ClientsApi.fetchOutstandingBalance(
        payload.client.id,
        auth,
      );
      const outstandingBalanceMoney = Dinero(outstandingBalance);
      const client = {
        ...payload.client,
        outstandingBalance,
        outstandingBalanceMoney,
      };

      const { date, paddingBefore, paddingAfter, serviceId } = appointment;

      const servicesPayload = await ServicesApi.fetchServicesForAppointment(
        {
          timezone,
          locationId: selectedLocation.id,
          staffId: selectedStaff.id,
          startTime: date.toISOString(),
          date: getDate(date),
        },
        auth,
      );
      const formattedServices = servicesPayload.map(makeOption)
      const services = filterServicesByStaff(
        formattedServices,
        selectedStaff,
      );

      const selectedService = formattedServices.find(({ id }) => id === serviceId);

      appointment = initializeBookingQuestionInAppointment(
        appointment,
        selectedService,
      );

      const [
        hasServiceExtraTime,
        serviceExtraTimeTooltipText,
      ] = getExtraTimeInfo(paddingBefore, paddingAfter);

      let errorInfo = {};

      const [startTime, endTime] = getStartAndEndTimeInBusinessTz({
        moment,
        date,
        time: appointment.time,
        duration: appointment.duration,
      });
      const areTimeBordersIncluded = areEventBordersIncludedInStaffWorkingHours({
        moment,
        start: startTime,
        end: endTime,
        dayShifts: selectedStaff.dayShifts,
      });
      if (!areTimeBordersIncluded) {
        errorInfo = {
          isServiceLineError: true,
          serviceLineSeverity: 'warning',
          isServiceLineMessage: 'The staff doesn\'t have available working hours in this time but you can save appointment anyway',
        };
      }

      try {
        const preparedAppointment = prepareAppointment(
          appointment,
          startTime,
          endTime,
        );
        const stringAppointment = JSON.stringify(preparedAppointment);
        await BookingApi.runAppointmentChecks(stringAppointment, auth);
      } catch (error) {
        if (error.name === 'OverlapAppointmentsByPaddingsError') {
          errorInfo = {
            isServiceLineError: true,
            serviceLineSeverity: 'warning',
            isServiceLineMessage: composeOverlapByPaddingsErrorMessage(
              error.body,
              timezone,
            ),
          };
        } else if (error.name === 'OverlapBusyTimesError') {
          errorInfo = {
            isServiceLineError: true,
            serviceLineSeverity: 'warning',
            isServiceLineMessage: composeOverlapByBusyTimeErrorMessage(
              error.body,
              timezone,
            ),
          };
        } else if (error.name === 'UseBusyResourceError') {
          errorInfo = {
            isServiceLineError: true,
            serviceLineSeverity: 'error',
            isServiceLineMessage: composeAppointmentBusyResourceErrorMessage(
              error.body,
              timezone,
            ),
          };
        } else {
          getSessionError(error, handleDisplayFlashMessage)
        }
      }

      let resourceItems = [];
      let selectedResourceItem = null;
      if (selectedService?.resourceId) {
        const options = {
          timezone,
          paddingBefore,
          paddingAfter,
          resourceId: selectedService?.resourceId,
          locationId: selectedLocation.id,
          staffId: selectedStaff.id,
          startTime: startTime.toISOString(),
          endTime: endTime.toISOString()
        };
        const resourceItemsPayload = await ResourcesApi.fetchResourceItemsForAppointment(
          options,
          auth,
        );
        resourceItems = resourceItemsPayload.map(makeOption);
        selectedResourceItem = resourceItems.find(({ id }) => {
          return id === appointment.resourceItemId;
        });
      }
      this.setState({
        ...errorInfo,
        workingStaff,
        selectedStaff,
        services,
        selectedService,
        appointment,
        hasServiceExtraTime,
        serviceExtraTimeTooltipText,
        client,
        resourceItems,
        selectedResourceItem,
        isLoading: false,
        isEditAction: true,
      });
    } catch (err) {
      if (err?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage('Unexpected error, please try again', 'error');
      }
      this.setState({isLoading: false})
      throw err
    }
  }

  handleOpenCreditCardForm() {
    this.setState({ isCreditCardFormDialogOpened: true });
  }

  handleCloseCreditCardForm() {
    this.setState({
      isCreditCardFormDialogOpened: false,
      stripeResponse: {},
      stripeCardVerify: {},
    });
  }

  async creditCardHandler(value) {
    this.setState({ stripeResponse: value, isCreditCardFormDialogOpened: false, isLoading: true });
    if(this.state.stripeResponse.hasOwnProperty('paymentMethod') && this.state.client.id !== '') {
      const { payload } = await this.props.verifyClientCard(
        {
          clientId: this.state.client.id,
          name: `${this.state.client.firstName} ${this.state.client.lastName}`,
          email: this.state.client.email,
          phone: this.state.client.mobileNumber,
          source: this.state?.stripeResponse?.paymentMethod.id,
          businessId: this.state?.salesSettings?.BusinessId
        });
      this.setState({ stripeCardVerify: payload, isLoading: false});
      if(this.state.stripeCardVerify?.status === 'succeeded') {
        this.props.handleDisplayFlashMessage('Payment method has been successfully added');
        this.setState({
          stripeError: false
        });
      }else{
        this.setState({
          isLoading: false,
          isCreditCardFormDialogOpened: false,
          stripeResponse: {},
          stripeCardVerify: {},
          stripeError: true
        });
        this.props.handleDisplayFlashMessage(`Adding payment method ${payload?.code}`, 'error');
      }
    }
  }

  handleTabChange(event, value) {
    this.setState({ currentTab: value });
  }

  async handleChangeClient(name, value) {
    this.setState(({ client }) => ({
      client: {
        ...client,
        [name]: value,
      },
    }));
  }

  async handleSelectClient(result, setSearchValue) {
    const { auth } = this.props;

    const outstandingBalance = await ClientsApi.fetchOutstandingBalance(
      result.id,
      auth,
    );
    const outstandingBalanceMoney = Dinero(outstandingBalance);
    this.setState({
      client: {
        ...result,
        outstandingBalance,
        outstandingBalanceMoney,
        mobileNumber: result.mobileNumber || '',
        email: result.email || '',
      },
      isDisabledClientFields: true,
    });
    setSearchValue('');
  }

  handleEnableClientFields(searchValue, { setSearchValue }) {
    this.setState({
      isDisabledClientFields: false,
      client: {
        ...initializeClient,
        ...searchValue,
      },
    });
    setSearchValue('');
  }

  async handleChangeService({ value }) {
    this.setState({ isLoading: true });

    const { services, selectedStaff, appointment: originalAppointment } = this.state;
    const { business: { timezone }, selectedLocation, auth, handleDisplayFlashMessage } = this.props;
    let appointment = { ...originalAppointment };

    const selectedService = services.find(({ id }) => id === value);
    const {
      paddingBefore,
      paddingAfter,
      duration,
      price,
      id: serviceId,
    } = selectedService;

    appointment = updateBookingQuestionInAppointment(
      appointment,
      selectedService,
    );

    const [
      hasServiceExtraTime,
      serviceExtraTimeTooltipText,
    ] = getExtraTimeInfo(paddingBefore, paddingAfter);

    let newAppointment = {
      ...appointment,
      duration,
      paddingBefore,
      paddingAfter,
      price,
      serviceId,
    };
    newAppointment = moneytizeObject(newAppointment, 'price');

    const [startTime, endTime] = getStartAndEndTimeInBusinessTz({
      moment,
      date: newAppointment.date,
      time: newAppointment.time,
      duration: newAppointment.duration,
    });

    let resourceItems = [];
    let selectedResourceItem = null;
    if (selectedService?.resourceId) {
      const options = {
        timezone,
        paddingBefore,
        paddingAfter,
        resourceId: selectedService?.resourceId,
        locationId: selectedLocation.id,
        staffId: selectedStaff.id,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString()
      };
      try {
        const resourceItemsPayload = await ResourcesApi.fetchResourceItemsForAppointment(
          options,
          auth,
        );
        resourceItems = resourceItemsPayload.map(makeOption);
        selectedResourceItem = resourceItems.find(({ id }) => {
          return id === newAppointment.resourceItemId;
        });
        const freeItem = resourceItems.find(({ isFree }) => isFree);
        if (!selectedResourceItem && freeItem) {
          selectedResourceItem = freeItem;
          newAppointment = {
            ...newAppointment,
            resourceItemId: freeItem.id,
          };
        }
      } catch (err) {
        if (err?.message?.includes('Session is expired')) {
          handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
        } else {
          handleDisplayFlashMessage('Unexpected error, please try again', 'error');
        }
        this.setState({isLoading: false})
        throw err
      }
    } else {
      newAppointment = {
        ...newAppointment,
        resourceItemId: '',
      };
    }

    let areTimeBordersIncluded = areEventBordersIncludedInLocationWorkingHours({
      moment,
      start: startTime,
      end: endTime,
      beginTime: selectedLocation.beginTime,
      endTime: selectedLocation.endTime,
    });
    if (!areTimeBordersIncluded) {
      this.setState({
        selectedService,
        hasServiceExtraTime,
        serviceExtraTimeTooltipText,
        resourceItems,
        selectedResourceItem,
        appointment: newAppointment,
        isServiceLineError: true,
        serviceLineSeverity: 'error',
        isServiceLineMessage: 'The appointment goes beyond of location\'s working hours',
        isLoading: false,
      });
      return;
    }

    try {
      const preparedAppointment = prepareAppointment(
        newAppointment,
        startTime,
        endTime,
      );
      const stringAppointment = JSON.stringify(preparedAppointment);
      await BookingApi.runAppointmentChecks(stringAppointment, auth);
    } catch (error) {
      if (error.name === 'OverlapAppointmentsByDurationError') {
        this.setState({
          selectedService,
          hasServiceExtraTime,
          serviceExtraTimeTooltipText,
          resourceItems,
          selectedResourceItem,
          appointment: newAppointment,
          isLoading: false,
          isServiceLineError: true,
          serviceLineSeverity: 'error',
          isServiceLineMessage: composeOverlapByDurationErrorMessage(
            error.body,
            timezone,
          ),
        });
        return;
      }
      if (error.name === 'OverlapAppointmentsByPaddingsError') {
        this.setState({
          selectedService,
          hasServiceExtraTime,
          serviceExtraTimeTooltipText,
          resourceItems,
          selectedResourceItem,
          appointment: newAppointment,
          isLoading: false,
          isServiceLineError: true,
          serviceLineSeverity: 'warning',
          isServiceLineMessage: composeOverlapByPaddingsErrorMessage(
            error.body,
            timezone,
          ),
        });
        return;
      }
      if (error.name === 'OverlapBusyTimesError') {
        this.setState({
          selectedService,
          hasServiceExtraTime,
          serviceExtraTimeTooltipText,
          resourceItems,
          selectedResourceItem,
          appointment: newAppointment,
          isLoading: false,
          isServiceLineError: true,
          serviceLineSeverity: 'warning',
          isServiceLineMessage: composeOverlapByBusyTimeErrorMessage(
            error.body,
            timezone,
          ),
        });
        return;
      }
      if (error.name === 'UseBusyResourceError') {
        this.setState({
          selectedService,
          hasServiceExtraTime,
          serviceExtraTimeTooltipText,
          resourceItems,
          selectedResourceItem,
          appointment: newAppointment,
          isLoading: false,
          isServiceLineError: true,
          serviceLineSeverity: 'error',
          isServiceLineMessage: composeAppointmentBusyResourceErrorMessage(
            error.body,
            timezone,
          ),
        });
        return;
      }
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage('Unexpected error, please try again', 'error');
      }
      this.setState({
        resourceItems,
        selectedResourceItem,
        isLoading: false,
      });
      throw error
    }

    areTimeBordersIncluded = areEventBordersIncludedInStaffWorkingHours({
      moment,
      start: startTime,
      end: endTime,
      dayShifts: selectedStaff.dayShifts,
    });
    if (!areTimeBordersIncluded) {
      this.setState({
        selectedService,
        hasServiceExtraTime,
        serviceExtraTimeTooltipText,
        resourceItems,
        selectedResourceItem,
        appointment: newAppointment,
        isServiceLineError: true,
        serviceLineSeverity: 'warning',
        isServiceLineMessage: 'The staff doesn\'t have available working hours in this time but you can save appointment anyway',
        isLoading: false,
      });
      return;
    }

    this.setState({
      selectedService,
      hasServiceExtraTime,
      serviceExtraTimeTooltipText,
      resourceItems,
      selectedResourceItem,
      appointment: newAppointment,
      isServiceLineError: false,
      serviceLineSeverity: '',
      isServiceLineMessage: '',
      isLoading: false,
    });
  }

  handleChangeAppointment(name, value) {
    this.setState(({ appointment }) => ({
      appointment: {
        ...appointment,
        [name]: value,
      },
    }));
  }

  async handleChangeServiceRow(name, value) {
    this.setState({ isLoading: true });

    const { appointment, selectedStaff } = this.state;
    const { business: { timezone }, selectedLocation, auth, handleDisplayFlashMessage } = this.props;

    const newAppointment = {
      ...appointment,
      [name]: value,
    };

    if (!newAppointment.serviceId) { // Service can be null
      this.setState({
        appointment: newAppointment,
        isServiceLineError: false,
        serviceLineSeverity: '',
        isServiceLineMessage: '',
        isLoading: false,
      });
      return;
    }

    const [startTime, endTime] = getStartAndEndTimeInBusinessTz({
      moment,
      date: newAppointment.date,
      time: newAppointment.time,
      duration: newAppointment.duration,
    });

    let areTimeBordersIncluded = areEventBordersIncludedInLocationWorkingHours({
      moment,
      start: startTime,
      end: endTime,
      beginTime: selectedLocation.beginTime,
      endTime: selectedLocation.endTime,
    });
    if (!areTimeBordersIncluded) {
      this.setState({
        appointment: newAppointment,
        isServiceLineError: true,
        serviceLineSeverity: 'error',
        isServiceLineMessage: 'The appointment goes beyond of location\'s working hours',
        isLoading: false,
      });
      return;
    }

    try {
      const preparedAppointment = prepareAppointment(
        newAppointment,
        startTime,
        endTime,
      );
      const stringAppointment = JSON.stringify(preparedAppointment);
      await BookingApi.runAppointmentChecks(stringAppointment, auth);
    } catch (error) {
      if (error.name === 'OverlapAppointmentsByDurationError') {
        this.setState({
          appointment: newAppointment,
          isLoading: false,
          isServiceLineError: true,
          serviceLineSeverity: 'error',
          isServiceLineMessage: composeOverlapByDurationErrorMessage(
            error.body,
            timezone,
          ),
        });
        return;
      }
      if (error.name === 'OverlapAppointmentsByPaddingsError') {
        this.setState({
          appointment: newAppointment,
          isLoading: false,
          isServiceLineError: true,
          serviceLineSeverity: 'warning',
          isServiceLineMessage: composeOverlapByPaddingsErrorMessage(
            error.body,
            timezone,
          ),
        });
        return;
      }
      if (error.name === 'OverlapBusyTimesError') {
        this.setState({
          appointment: newAppointment,
          isLoading: false,
          isServiceLineError: true,
          serviceLineSeverity: 'warning',
          isServiceLineMessage: composeOverlapByBusyTimeErrorMessage(
            error.body,
            timezone,
          ),
        });
        return;
      }
      if (error.name === 'UseBusyResourceError') {
        this.setState({
          appointment: newAppointment,
          isLoading: false,
          isServiceLineError: true,
          serviceLineSeverity: 'error',
          isServiceLineMessage: composeAppointmentBusyResourceErrorMessage(
            error.body,
            timezone,
          ),
        });
        return;
      }
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage('Unexpected error, please try again', 'error');
      }
      this.setState({ isLoading: false });
      throw error
    }

    areTimeBordersIncluded = areEventBordersIncludedInStaffWorkingHours({
      moment,
      start: startTime,
      end: endTime,
      dayShifts: selectedStaff.dayShifts,
    });
    if (!areTimeBordersIncluded) {
      this.setState({
        appointment: newAppointment,
        isServiceLineError: true,
        serviceLineSeverity: 'warning',
        isServiceLineMessage: 'The staff doesn\'t have available working hours in this time but you can save appointment anyway',
        isLoading: false,
      });
      return;
    }

    this.setState({
      appointment: newAppointment,
      isServiceLineError: false,
      serviceLineSeverity: '',
      isServiceLineMessage: '',
      isLoading: false,
    });
  }

  // handleChangeLocation({ value }) {
  //   this.setState(({ appointment }) => ({
  //     appointment: {
  //       ...appointment,
  //       locationId: value,
  //       staffId: '',
  //       serviceId: '',
  //       isServiceLineError: false,
  //       serviceLineSeverity: '',
  //     },
  //   }));
  // }

  async createClientHandler() {
    const { createClient } = this.props
    if(this.state.client.firstName !== ''
      && this.state.client.lastName !== ''
      && this.state.client.email !== ''
      && this.state.client.mobileNumber !== ''
      && this.state.client.id === '') {
      if(this.state.clientAlreadyCreate === false) {
        const { BusinessId } = this.state.salesSettings
        this.setState({ isLoading: true });
        const { payload } = await createClient({...this.state.client, businessId: BusinessId})
        this.setState((prev) => ({
          ...prev,
          client: {
            id: payload.id,
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            mobileNumber: payload.mobileNumber,
          },
          clientAlreadyCreate: true,
          isLoading: false,
        }));
      }
    }
  }

  async createAppointmentHandler() {
    const { client, appointment } = this.state
    const { auth: { currentStaff } } = this.props;

    const options = { currentStaffId: currentStaff.id };
    const [startTime, endTime] = getStartAndEndTimeInBusinessTz({
      moment,
      date: appointment.date,
      time: appointment.time,
      duration: appointment.duration,
    });
    const preparedAppointment = prepareAppointment(
      appointment,
      startTime,
      endTime,
    );
    const data = {
      client,
      appointment: preparedAppointment,
      siteUrl: window.location.origin,
      currentClientTime: getCurrentClientTime(),
    };
    if(this.state.stripeError === false) {
      this.setState({ isLoading: true });
      await this.props.createAppointmentWithExtras(data, options);
      this.setState({ isLoading: false });
      this.props.onUpdateEvent();
      this.props.handleCloseAppointmentDialog();
    }
  }

  async createClientCardHandler() {
    const { getClientCards, auth } = this.props

    const { payload: { count } } = await getClientCards(this.state.client.id);
    const newClient = await ClientsApi.fetchClient(
      this.state.client.id,
      auth,
    );
    const isActiveClient = newClient?.isActiveClient
    const isRequiredCard = (this.state.salesSettings.isPayWithCard === true || newClient?.isCardRequired === true || (this.state.salesSettings.isPayWithCardForNewClient === true && (newClient?.isNew === true || !isActiveClient))) && (count <= 0 || !isActiveClient);

    if(this.state.salesSettings.stripeConnected === true && isRequiredCard) {
      if(this.state.stripeCardVerify?.status !== 'succeeded') {
        this.handleOpenCreditCardForm()
      }
      this.setState({
        isLoading: true,
        proceedSavingAction: this.onClickCreateAppointment,
      });
      if(this.state.stripeCardVerify?.status === 'succeeded') {
        if(this.state.stripeCardAlreadyAdd === false) {
          if(this.state.stripeError === false) {
            const {paymentMethod, paymentMethod: { card }} = this.state?.stripeResponse;
            const createClientCardData = {
              token: paymentMethod.id,
              clientId: this.state.client.id,
              brand: card.brand,
              customerId: this.state.stripeCardVerify?.customerId,
              businessId: this.state.salesSettings.BusinessId,
              country: card.country,
              expMonth: card.exp_month,
              expYear: card.exp_year,
              funding: card.funding,
              last4: card.last4,
            };
            await this.props.createClientCard(createClientCardData);
            await this.createAppointmentHandler()
            this.setState({ isLoading: false, stripeCardAlreadyAdd: true });
          }
        }
      }
    }else{
      await this.createAppointmentHandler()
    }
  }

  async onClickCreateAppointment() {
    const { appointment, client, selectedService } = this.state;
    const {
      selectedLocation,
      business: { timezone },
      handleDisplayFlashMessage,
      updateGetStartedStep,
      router,
      loadGetStartedStep,
      auth
    } = this.props;

    const {
      isValid: isValidAppointment,
      errors: appointmentErrors,
    } = validateObjectByMap(
      appointment,
      appointmentErrorsMap,
      { resourceId: selectedService?.resourceId },
    );
    const {
      isValid: isValidClient,
      errors: clientErrors,
    } = validateObjectByMap(client, clientErrorsMap);
    if (!isValidAppointment || !isValidClient) {
      this.setState({
        appointmentErrors,
        clientErrors,
        isDisabledClientFields: !!isValidClient,
      });
      return;
    }
    const isServiceBookingAnswerPresent = checkServiceBookingAnswerPresence(
      appointment,
      selectedService,
    );
    // if (!isServiceBookingAnswerPresent && this.state.isCreditCardFormDialogOpened === false) {
    if (!isServiceBookingAnswerPresent) {
      this.setState({
        isServiceQuestionDialogOpened: true,
        proceedSavingAction: this.onClickCreateAppointment,
      });
      return;
    }
    // const selectedStaff = staff.find((staff) => staff.id === appointment.staffId);

    const [startTime, endTime] = getStartAndEndTimeInBusinessTz({
      moment,
      date: appointment.date,
      time: appointment.time,
      duration: appointment.duration,
    });

    const areTimeBordersIncluded = areEventBordersIncludedInLocationWorkingHours({
      moment,
      start: startTime,
      end: endTime,
      beginTime: selectedLocation.beginTime,
      endTime: selectedLocation.endTime,
    });
    if (!areTimeBordersIncluded) {
      this.setState({
        appointmentErrors: {},
        clientErrors: {},
        isServiceLineError: true,
        serviceLineSeverity: 'error',
        isServiceLineMessage: 'The appointment goes beyond of location\'s working hours',
      });
      return;
    }

    this.setState({
      appointmentErrors: {},
      clientErrors: {},
      isServiceLineError: false,
      serviceLineSeverity: '',
      isServiceLineMessage: '',
      isLoading: true,
    });

    try {
      if(client.id !== '') {
        await this.createClientCardHandler()
      }else{
        await this.createClientHandler()
        await this.createClientCardHandler()
      }

      const loadGetStarted = await loadGetStartedStep(auth?.userId);
      const checkGetStartedComplete = loadGetStarted?.payload?.filter((value) => value?.GetStarted?.priority === 5)?.[0]?.isComplete
      if(checkGetStartedComplete === false && auth?.currentStaff?.roleName === 'Owner') {
        const resGetStarted = await updateGetStartedStep({userId: auth?.userId, option: 5})
        if(resGetStarted) {
          router?.push('/settings/get-started')
        }
      }

      this.setState({ isLoading: false });
      // this.props.onUpdateEvent();
      // this.props.handleCloseAppointmentDialog();
    } catch (error) {
      if (error.name === 'OverlapAppointmentsByDurationError') {
        this.setState({
          isLoading: false,
          isServiceLineError: true,
          serviceLineSeverity: 'error',
          isServiceLineMessage: composeOverlapByDurationErrorMessage(
            error.body,
            timezone,
          ),
        });
        return;
      }
      if (error.name === 'UseBusyResourceError') {
        this.setState({
          isLoading: false,
          isServiceLineError: true,
          serviceLineSeverity: 'error',
          isServiceLineMessage: composeAppointmentBusyResourceErrorMessage(
            error.body,
            timezone,
          ),
        });
        return;
      }
      if (error.name === 'UnmatchedClientFieldError') {
        handleDisplayFlashMessage(error.message, 'error');
        this.setState({isLoading: false})
        return;
      }
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage('Unexpected error, please try again', 'error');
      }
      this.setState({isLoading: false})
      throw error
    }
  }

  async onClickSaveAppointment() {
    const {
      client,
      selectedService,
      appointment: { id, ...appointment },
    } = this.state;
    const {
      selectedLocation,
      business: { timezone },
      auth: { currentStaff },
      updateAppointmentWithExtras,
      onUpdateEvent,
      handleCloseAppointmentDialog,
      handleDisplayFlashMessage
    } = this.props;

    const {
      isValid: isValidAppointment,
      errors: appointmentErrors,
    } = validateObjectByMap(
      appointment,
      appointmentErrorsMap,
      { resourceId: selectedService?.resourceId },
    );
    const {
      isValid: isValidClient,
      errors: clientErrors,
    } = validateObjectByMap(client, clientErrorsMap);
    if (!isValidAppointment || !isValidClient) {
      this.setState({
        appointmentErrors,
        clientErrors,
        isDisabledClientFields: !!isValidClient,
      });
      return;
    }
    const isServiceBookingAnswerPresent = checkServiceBookingAnswerPresence(
      appointment,
      selectedService,
    );
    if (!isServiceBookingAnswerPresent) {
      this.setState({
        isServiceQuestionDialogOpened: true,
        proceedSavingAction: this.onClickSaveAppointment,
      });
      return;
    }

    // const selectedStaff = staff.find((staff) => staff.id === appointment.staffId);

    const [startTime, endTime] = getStartAndEndTimeInBusinessTz({
      moment,
      date: appointment.date,
      time: appointment.time,
      duration: appointment.duration,
    });

    const areTimeBordersIncluded = areEventBordersIncludedInLocationWorkingHours({
      moment,
      start: startTime,
      end: endTime,
      beginTime: selectedLocation.beginTime,
      endTime: selectedLocation.endTime,
    });
    if (!areTimeBordersIncluded) {
      this.setState({
        appointmentErrors: {},
        clientErrors: {},
        isServiceLineError: true,
        serviceLineSeverity: 'error',
        isServiceLineMessage: 'The appointment goes beyond of location\'s working hours',
      });
      return;
    }

    this.setState({
      appointmentErrors: {},
      clientErrors: {},
      isServiceLineError: false,
      serviceLineSeverity: '',
      isServiceLineMessage: '',
      isLoading: true,
    });

    try {
      const preparedAppointment = prepareAppointment(
        appointment,
        startTime,
        endTime,
      );
      const data = {
        client,
        appointment: preparedAppointment,
        siteUrl: window.location.origin,
        currentClientTime: getCurrentClientTime(),
      };

      const options = { currentStaffId: currentStaff.id };
      await updateAppointmentWithExtras(id, data, options);

      this.setState({ isLoading: false });
      onUpdateEvent();
      handleCloseAppointmentDialog();
    } catch (error) {
      if (error.name === 'OverlapAppointmentsByDurationError') {
        this.setState({
          isLoading: false,
          isServiceLineError: true,
          serviceLineSeverity: 'error',
          isServiceLineMessage: composeOverlapByDurationErrorMessage(
            error.body,
            timezone,
          ),
        });
        return;
      }
      if (error.name === 'UseBusyResourceError') {
        this.setState({
          isLoading: false,
          isServiceLineError: true,
          serviceLineSeverity: 'error',
          isServiceLineMessage: composeAppointmentBusyResourceErrorMessage(
            error.body,
            timezone,
          ),
        });
        return;
      }
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage('Unexpected error, please try again', 'error');
      }
      this.setState({isLoading: false})
      throw error
    }
  }

  async onClickNotesUpdate() {
    const {
      appointment: { id, notes},
    } = this.state;
    const {
      business: { timezone },
      auth: { currentStaff },
      updateNotesOnly,
      onUpdateEvent,
      handleCloseAppointmentDialog,
      handleDisplayFlashMessage
    } = this.props;

    this.setState({
      isLoading: true,
    });

    try {
      const data = {
        notes: notes || '',
        timezone
      };
      const options = { currentStaffId: currentStaff.id };
      await updateNotesOnly(id, data, options);
      this.setState({ isLoading: false });
      onUpdateEvent();
      handleCloseAppointmentDialog();
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage('Unexpected error, please try again', 'error');
      }
      this.setState({isLoading: false})
    }
  }

  handleOpenCancelDialog() {
    const { appointment } = this.state;
    const { handleDisplayFlashMessage } = this.props;
    if (appointment?.invoiceId) {
      handleDisplayFlashMessage(
        'You must delete the attached invoice before cancelling the appointment', 'error'
      );
      return;
    }

    this.setState({ isCancelDialogOpened: true });
  }

  handleCloseCancelDialog() {
    this.setState({ isCancelDialogOpened: false });
  }

  async handleCancelAppointment(reasonId, reasonLabel) {
    const { appointment: { id } } = this.state;
    const { auth: { currentStaff }, cancelAppointment, cancelAppointmentCallback } = this.props

    try {
      const data = {
        cancellationReasonId: reasonId,
        siteUrl: window.location.origin,
        currentClientTime: getCurrentClientTime(),
      };
      const options = {
        currentStaffId: currentStaff.id,
        cancellationReasonLabel: reasonLabel,
      };

      await cancelAppointment(id, data, options);

      cancelAppointmentCallback(null, id);

      this.setState({ isCancelDialogOpened: false });
    } catch (error) {
      cancelAppointmentCallback(error);
    }
  }

  async handleCreateInvoice() {
    this.setState({ isLoading: true });

    const { appointment: { id }} = this.state;
    const { auth: { currentStaff }, createInvoiceWithAppointmentItem, handleDisplayFlashMessage } = this.props;

    const options = {
      appointmentId: id,
      currentStaffId: currentStaff.id,
      currentClientTime: getCurrentClientTime(),
    };
    try {
      const {
        payload,
      } = await createInvoiceWithAppointmentItem(options);
      this.handleAppointmentCallback();

      this.setState({
        isLoading: false,
        isInvoiceDialogOpened: true,
        currentInvoiceId: payload.id,
      });
    } catch (err) {
      if (err?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage('Unexpected error, please try again', 'error');
      }
      this.setState({isLoading: false})
      throw err
    }
  }

  handleOpenEditInvoiceDialog = invoiceId => {
    this.setState({
      isInvoiceDialogOpened: true,
      currentInvoiceId: invoiceId,
    });
  };

  handleCloseInvoiceDialog() {
    this.setState({
      isInvoiceDialogOpened: false,
      currentInvoiceId: '',
    });
  }

  handleOpenSOAPNoteDialog() {
    this.setState({ isSOAPNoteDialogOpened: true });
  }

  handleCloseSOAPNoteDialog() {
    this.setState({ isSOAPNoteDialogOpened: false });
  }

  async handleCreateSOAPNote() {
    this.setState({ isLoading: true });

    const { appointment: { id }} = this.state;
    const { auth, handleDisplayFlashMessage } = this.props;
    const { currentStaff } = auth;

    try {
      const options = {
        appointmentId: id,
        currentStaffId: currentStaff.id,
      };
      await SoapNotesApi.createSOAPNote(options, auth);

      this.handleAppointmentCallback();

      this.setState({
        isLoading: false,
        isSOAPNoteDialogOpened: true,
      });
    } catch (err) {
      if (err?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage('Unexpected error, please try again', 'error');
      }
      this.setState({isLoading: false})
      throw err
    }
  }

  async handleAppointmentCallback() {
    const { client, appointment: { id } } = this.state;
    const { auth, selectedEvent: { start }, loadAppointment } = this.props;

    const { payload } = await loadAppointment(id);
    const appointment = initializeAppointment(payload, start, moment);

    const outstandingBalance = await ClientsApi.fetchOutstandingBalance(
      client.id,
      auth,
    );
    const outstandingBalanceMoney = Dinero(outstandingBalance);
    this.setState({
      appointment,
      client: {
        ...client,
        outstandingBalance,
        outstandingBalanceMoney,
      },
    });
  }

  handleOnCloseAppointmentDialog() {
    const { onUpdateEvent, handleCloseAppointmentDialog } = this.props;
    onUpdateEvent();
    handleCloseAppointmentDialog();
  }

  onClickCloseServiceQuestionDialog() {
    this.setState({
      isServiceQuestionDialogOpened: false,
    });
  }

  saveServiceBookingAnswerAndProceed(serviceBookingAnswer) {
    const { appointment, proceedSavingAction } = this.state;

    this.setState({
      appointment: {
        ...appointment,
        serviceBookingAnswer,
      },
      isServiceQuestionDialogOpened: false,
    }, proceedSavingAction);
  }

  render() {
    const {
      currentTab,
      isLoading,
      isCancelDialogOpened,
      isInvoiceDialogOpened,
      client,
      currentInvoiceId,
      isSOAPNoteDialogOpened,
      appointment,
      isEditAction,
      isServiceQuestionDialogOpened,
      isCreditCardFormDialogOpened
    } = this.state;
    const {
      classes,
      handleStartChangingAppointment,
      ...restProps
    } = this.props;

    const CurrentTabComponent = tabs.find(({ value }) => value === currentTab).component;

    if (isSOAPNoteDialogOpened) {
      return (
        <SoapNote
          {...restProps}
          selectedAppointmentId={appointment.id}
          handleCloseSOAPNoteDialog={this.handleCloseSOAPNoteDialog}
          onExitCallback={this.handleAppointmentCallback}
          mainStyle={{padding: '7px'}}
        />
      )
    }

    return (
      <>
        <CustomDialogTitle data-testid={`appointmentDialog-${appointment.id}`} onClose={this.handleOnCloseAppointmentDialog}>
          Appointment
        </CustomDialogTitle>
        <CustomDialogContent className={classes.dialogContent}>
          <Toolbar
            position="static"
            classes={{
              root: classes.toolbar
            }}
          >
            <Tabs
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
                flexContainer: classes.flexContainer,
              }}
              variant="fullWidth"
              value={currentTab}
              onChange={this.handleTabChange}
            >
              {tabs.map((tab) => (
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  label={tab.label}
                  value={tab.value}
                  disabled={tab.disabled}
                  key={tab.value}
                />
              ))}
            </Tabs>
          </Toolbar>
          <main className={classes.main}>
            {(appointment.isChangeable === false) && (
              <OrangeInfoStrip className={classes.noChangeReasonBox}>
                <Typography variant="subtitle1">
                  {getNoChangeAppointmentReason(appointment)}
                </Typography>
              </OrangeInfoStrip>
            )}
            <CurrentTabComponent
              {...this.state}
              {...this.props}
              handleChangeAppointment={this.handleChangeAppointment}
              handleChangeServiceRow={this.handleChangeServiceRow}
              handleChangeClient={this.handleChangeClient}
              handleSelectClient={this.handleSelectClient}
              handleEnableClientFields={this.handleEnableClientFields}
              handleChangeService={this.handleChangeService}
              // handleChangeLocation={this.handleChangeLocation}
            />
          </main>
        </CustomDialogContent>
        <CustomDialogActions className={classes.buttonBox}>
          <Grid container className={classes.leftButtons}>
            {isEditAction && (
              <>
                <Hidden mdDown>
                  {appointment.invoiceId ? (
                    <OrangeButton
                      variant="contained"
                      size="medium"
                      onClick={() => this.handleOpenEditInvoiceDialog(appointment.invoiceId)}
                      className={classes.button}
                    >
                      Invoice
                    </OrangeButton>
                  ) : (
                    <OrangeButton
                      variant="contained"
                      size="medium"
                      onClick={this.handleCreateInvoice}
                      className={classes.button}
                      startIcon={(
                        <PlusIcon
                          className={classes.plusIcon}
                          fillColor="#ffffff"
                        />
                      )}
                    >
                      Invoice
                    </OrangeButton>
                  )}
                  {appointment.isSoapRequired && (
                    appointment.soapNoteId ? (
                      <OrangeButton
                        variant="contained"
                        size="medium"
                        onClick={this.handleOpenSOAPNoteDialog}
                        className={classes.button}
                      >
                        Chart
                      </OrangeButton>
                    ) : (
                      <OrangeButton
                        variant="contained"
                        size="medium"
                        onClick={this.handleCreateSOAPNote}
                        className={classes.button}
                        startIcon={(
                          <PlusIcon
                            className={classes.plusIcon}
                            fillColor="#ffffff"
                          />
                        )}
                      >
                        Chart
                      </OrangeButton>
                    )
                  )}
                  {appointment.isChangeable && (
                    <RedButton
                      variant="contained"
                      size="medium"
                      onClick={this.handleOpenCancelDialog}
                      className={classes.redButton}
                    >
                      Cancel
                    </RedButton>
                  )}
                </Hidden>
                <Hidden mdUp>
                  <MobileMenu>
                    <MenuItem>
                      {appointment.invoiceId ? (
                        <Grid
                          onClick={() => this.handleOpenEditInvoiceDialog(
                            appointment.invoiceId,
                          )}
                          className={classes.invoiceItem}
                        >
                          Invoice
                          {appointment.isInvoicePaid && (
                            <DoneIcon
                              titleAccess="Invoice paid"
                              className={classes.doneIcon}
                            />
                          )}
                        </Grid>
                      ) : (
                        <Grid
                          container
                          onClick={this.handleCreateInvoice}
                          className={classes.mobuleInvoicePlusBox}
                        >
                          <Grid item>Invoice</Grid>
                          <Grid container className={classes.mobilePlusIconBox}>
                            <PlusIcon
                              className={classes.plusIcon}
                              fillColor="#000000"
                            />
                          </Grid>
                        </Grid>
                      )}
                    </MenuItem>
                    {appointment.isSoapRequired && (
                      <MenuItem>
                        {appointment.soapNoteId ? (
                          <Grid onClick={this.handleOpenSOAPNoteDialog} className={classes.invoiceItem}>
                            Chart
                            {appointment.isSoapLocked && (
                              <DoneIcon
                                titleAccess="Chart locked"
                                className={classes.doneIcon}
                              />
                            )}
                          </Grid>
                        ) : (
                          <Grid
                            container
                            onClick={this.handleCreateSOAPNote}
                            className={classes.mobuleInvoicePlusBox}
                          >
                            <Grid container className={classes.mobilePlusIconBox}>
                              <PlusIcon
                                className={classes.plusIcon}
                                fillColor="#000000"
                              />
                            </Grid>
                            <Grid item>Chart</Grid>
                          </Grid>
                        )}
                      </MenuItem>
                    )}
                    {appointment.isChangeable && (
                      <MenuItem red>
                        <Grid onClick={this.handleOpenCancelDialog}>
                          Cancel
                        </Grid>
                      </MenuItem>
                    )}
                  </MobileMenu>
                </Hidden>
              </>
            )}
          </Grid>
          {this.state.stripeCardVerify?.status === 'succeeded' && (
            <Box sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center'
            }}
            >
              <CreditScoreIcon fontSize="large" sx={{color: green[400] }}/>
              <Typography sx={{
                marginLeft: '0.5rem',
                color: '#808190',
                fontSize: '20px',
                lineHeight: '26px',
              }}
              >Credit card successfully added
              </Typography>
            </Box>
          )}
          {appointment.isChangeable && (
            <Grid container className={classes.rightButtons}>
              {isEditAction && (
                <OrangeButton
                  variant="contained"
                  size="medium"
                  onClick={handleStartChangingAppointment}
                  className={cx(classes.button, classes.changeButton)}
                >
                  Change in calendar
                </OrangeButton>
              )}
              <GreenButton
                variant="contained"
                size="medium"
                onClick={isEditAction ? this.onClickSaveAppointment : this.onClickCreateAppointment}
                className={classes.button}
                ref={this.saveButtonAnchor}
              >
                {isEditAction ? 'Save' : 'Create'}
              </GreenButton>
            </Grid>
          )}
          {!appointment.isChangeable && !appointment.isCompleted && currentTab === 'Notes' && (
            <Grid container className={classes.rightButtons}>
              <GreenButton
                variant="contained"
                size="medium"
                onClick={this.onClickNotesUpdate}
                className={classes.button}
              >
                Save
              </GreenButton>
            </Grid>
          )}
        </CustomDialogActions>
        <CustomDialog
          maxWidth="sm"
          open={isCancelDialogOpened}
          onClose={this.handleCloseCancelDialog}
        >
          <AppointmentCancellation
            {...this.props}
            onClose={this.handleCloseCancelDialog}
            handleCancelAppointment={this.handleCancelAppointment}
          />
        </CustomDialog>
        <CustomDialog
          scroll="body"
          maxWidth="lg"
          open={isInvoiceDialogOpened}
          classes={{
            paperScrollBody: classes.paperScrollBody,
          }}
        >
          <Invoice
            {...this.props}
            client={client}
            currentInvoiceId={currentInvoiceId}
            handleCloseInvoiceDialog={this.handleCloseInvoiceDialog}
            onExitCallback={this.handleAppointmentCallback}
          />
        </CustomDialog>
        <CustomPopper
          open={isServiceQuestionDialogOpened}
          anchorEl={this.saveButtonAnchor.current}
          onClickAway={this.onClickCloseServiceQuestionDialog}
          placement="top-end"
        >
          <ServiceQuestion
            {...this.state}
            {...this.props}
            onClose={this.onClickCloseServiceQuestionDialog}
            saveServiceBookingAnswerAndProceed={
              this.saveServiceBookingAnswerAndProceed
            }
          />
        </CustomPopper>
        <CustomDialog
          open={isCreditCardFormDialogOpened}
          onClose={this.handleCloseCreditCardForm}
          placement="top"
        >
          <CustomDialogTitle onClose={this.handleCloseCreditCardForm}>
            Add Credit Card
          </CustomDialogTitle>
          <Card
            title="Credit Card Required"
            pargraph="Our policy requires a credit card on file to confirm this appointment. The payment details will be securely stored with Stripe. No charges will be made at this time."
            {...this.state}
            {...this.props}
            onClose={this.handleCloseCreditCardForm}
            creditCardHandler={this.creditCardHandler}
          />
        </CustomDialog>
        <CircularProgressWithBackdrop loading={isLoading} />
      </>
    );
  }
}

const mapStateToProps = ({
  locations,
  invoices,
  auth,
  cancellationReasons,
}) => ({
  locations,
  invoices,
  auth,
  cancellationReasons,
});

const mapDispatchToProps = dispatch => ({
  loadLocations: bindActionCreators(loadLocations, dispatch),
  loadSalesSettings: bindActionCreators(loadSalesSettings, dispatch),
  loadAppointment: bindActionCreators(loadAppointment, dispatch),
  createAppointmentWithExtras: bindActionCreators(
    createAppointmentWithExtras,
    dispatch,
  ),
  createClientCard: bindActionCreators(createClientCreditCard, dispatch),
  verifyClientCard: bindActionCreators(verifyClientCreditCard, dispatch),
  getClientCards: bindActionCreators(readClientCreditCards, dispatch),
  createClient: bindActionCreators(createClientForBooking, dispatch),
  updateAppointmentWithExtras: bindActionCreators(
    updateAppointmentWithExtras,
    dispatch,
  ),
  cancelAppointment: bindActionCreators(cancelAppointment, dispatch),
  createInvoiceWithAppointmentItem: bindActionCreators(
    createInvoiceWithAppointmentItem, dispatch,
  ),
  updateGetStartedStep: bindActionCreators(updateGetStartedStep, dispatch),
  loadGetStartedStep: bindActionCreators(loadGetStartedStep, dispatch),
  updateNotesOnly: bindActionCreators(updateNotesOnly, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(withStyles(AddAppointment, styles));
