/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
import React, { Component, useCallback, useMemo } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Calendar, Views, momentLocalizer } from '@nlevchuk/react-big-calendar';
import withDragAndDrop from '@nlevchuk/react-big-calendar/lib/addons/dragAndDrop';
import getStyledEvents from '@nlevchuk/react-big-calendar/lib/utils/layout-algorithms/overlap';
import moment from 'moment-timezone';
import {
  Drawer,
  Hidden,
  Grid
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import { ArrowForwardIos as ArrowForwardIcon, Margin } from '@mui/icons-material';
import { cx } from '@emotion/css'
import { isEmpty } from 'lodash';

import withPaddings from '../components/calendar/withPaddings';
import SideBar from '../components/calendar/SideBar';
import ToolBar from '../components/calendar/ToolBar';
import Event from '../components/calendar/Event';
import DayView from '../components/calendar/DayView';
import WeekView from '../components/calendar/WeekView';
import MonthEvent from '../components/calendar/MonthEvent';
import ChangeNavbar from '../components/calendar/ChangeNavbar';
import ClickMenu from '../components/calendar/ClickMenu';
import AllRosteredCalendarView from '../components/calendar/AllRosteredCalendarView';
import withThemedLayoutAndSession from '../hocs/withThemedLayoutAndSession';
import withMaxPageWidth from '../hocs/withMaxPageWidth';
import AddAppointment from '../components/AddAppointment';
import AddBusyTime from '../components/AddBusyTime';
import { loadLocationsForCalendar } from '../../../shared_slices/locationsSlice';
import { updateAppointment } from '../slices/appointmentsSlice';
import { loadBusiness, loadGetStartedStep } from '../../../shared_slices/businessesSlice';
import {
  buildRGBforBackgroundColor,
  buildRGBforPaddingColor,
  prepareQueryOptions,
  keepQueryAttrsInBrowser,
} from '../utils/calendarUtils';
import {
  composeOverlapByDurationErrorMessage,
  composeAppointmentBusyResourceErrorMessage,
  getFlashMessageWhileMovingAppointment,
} from '../utils/appointmentUtils';
import {
  areEventBordersIncludedInStaffWorkingHours,
  isSelectedTimeIncludedInStaffWorkingHours,
  doesStaffHaveActiveService,
  isSelectedTimeIncludedInLocationWorkingHours,
  areEventBordersIncludedInLocationWorkingHours,
} from '../utils/staffUtils';
import CustomDialog from '../../../shared_components/CustomDialog';
import { loadCancellationReasons } from '../slices/cancellationReasonsSlice';
import {
  getCurrentClientTime,
  getDate,
  getWeekday,
} from '../../../shared_client_utils/dateUtils';
import {
  timeDiffInMinutes,
  setHoursAndMinutesForDate,
} from '../../../shared_client_utils/momentUtils';
import {
  StaffApi,
  BookingApi,
  BusyTimesApi,
  SettingsApi,
} from '../../../client_http_api';
import calendarEventTypes from '../configs/calendarEventTypes';
import { navbarHeightMultiplier, calendarSidebarWidth } from '../../../shared_client_utils/theme';
import dayShiftTypes from '../configs/dayShiftTypes'

const localizer = momentLocalizer(moment);
const CalendarWithPlugins = compose(
  withDragAndDrop,
  withPaddings,
)(Calendar);

const styles = (theme, _, classes) => ({
  root: {
    display: "flex",
    height: "auto",
    minHeight: "100%",
    width: "100%",
    fontFamily: "SF Pro Display",
  },
  drawer: {
    width: calendarSidebarWidth,
  },
  drawerPaper: {
    zIndex: 10,
    width: calendarSidebarWidth,
    position: 'inherit',
  },
  content: {
    flex: 1,
    padding: theme.spacing(),
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
    },
  },
  tabsRoot: {},
  tabsIndicator: {
    borderBottom: 'none'
  },
  tabRoot: {
    minWidth: '60px',
    fontSize: 14,
    borderBottom: 'none',
    textTransform: 'initial',
    color: '#a5a5a5',
    fontFamily: [
      'SF Pro Display !important',
    ].join(','),

    '&:hover': {
      opacity: 1,
    },

    [`&.${classes.tabSelected}`]: {
      color: '#00bd3d',
      fontWeight: 'bold',
      borderBottom: 'none',
      background: '#fff',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing(3),
  },
  default_tabStyle: {
    fontSize: 11,
    backgroundColor: 'red',
  },
  active_tabStyle: {
    fontSize: 11,
    color: '#ffffff',
  },
  rootCalendarView: {
    '& .rbc-calendar': {
      height: `calc(100vh - ${theme.spacing(navbarHeightMultiplier + 2)})`,
      overflow: 'auto',

      '& .rbc-show-more': {
        color: '#686868'
      },
      '& .rbc-off-range': {
        color: '#393939'
      },
      '& .rbc-off-range-bg': {
        background: '#bbb !important;'
      },
      '& .rbc-time-view': {
        padding: theme.spacing(1 / 2),
        backgroundColor: '#ffffff',
        border: 'none',
        overflow: 'auto',

        '& .rbc-time-header': {
          '& .rbc-time-header-cell': {

            '& .rbc-header': {
              borderBottom: 'none',
              height: theme.spacing(4),
              color: '#8A888A',
              backgroundColor: '#ffffff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          },

          '& .rbc-row-resource': {
            borderBottom: 'none',

            '& .rbc-header': {
              borderBottom: 'none',
              height: theme.spacing(5),
              fontSize: theme.spacing(2),
              textTransform: 'uppercase',
              fontWeight: 600,
              color: '#8A888A',
              backgroundColor: '#ffffff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          },

          '& .rbc-allday-cell': {
            display: 'none',
          },
        },

        '& .rbc-timeslot-group': {
          height: '145px',
          flex: 'auto',

          '& .rbc-time-slot': {
            color: '#8A888A',
            backgroundColor: '#ffffff',
          }
        },

        '& .rbc-events-container': {
          '& .rbc-addons-dnd-resizable': {
            '& > .rbc-addons-dnd-resize-ns-anchor:first-child': {
              top: '2px',
            },
            '& > .rbc-addons-dnd-resize-ns-anchor:last-child': {
              bottom: '2px',
            },
          },

          '& .rbc-event-label': {
            display: 'none',
          },

          '& .rbc-event': {
            overflow: 'initial',

            '& .rbc-event-content': {
              overflow: 'hidden',
            },
          },
        },

        '& .rbc-current-time-indicator': {
          backgroundColor: theme.palette.primary.dark,
        },
      },

      '& .rbc-month-view': {
        padding: theme.spacing(1 / 2),
        backgroundColor: '#ffffff',
        border: 'none',

        '& .rbc-month-header': {
          '& .rbc-header': {
            height: theme.spacing(4),
            color: '#8A888A',
            backgroundColor: '#ffffff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },

        '& .rbc-month-row': {
          '& .rbc-row-content': {
            height: '100%',

            '& .rbc-row': {
              '& .rbc-date-cell': {
                '& > a': {
                  display: 'block',
                  width: '100%',
                  height: '100%',
                },
              },
            },
          },
        },
      },

      '& .rbc-today': {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
    }
  },
  appointmentPaper: {
    [theme.breakpoints.down('md')]: {
      overflowY: 'scroll',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '663px',
    },

    [theme.breakpoints.up('lg')]: {
      width: '67%',
    },
  },
  appointmentPaperFullScreen: {
    [theme.breakpoints.down('md')]: {
      height: '99%',
    },
  },
  staffWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: '#f9f9f9',
    // marginBottom: theme.spacing(),
    position: 'absolute',
    // left: '22%',
    top: '5.9em',
    left: '7%',
    zIndex: 1,
    background: 'transparent',
    height: '0',
    width: '100%',
  },
  staffContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '75%',
  },
  arrowBox: {
    padding: '0.5rem',
    width: 'auto',
    cursor: 'pointer',
  },
  leftArrowIcon: {
    transform: 'rotate(180deg)',
  },
  arrowIcon: {
    color: grey[700],
  },
  dateTitle: {
    fontSize: theme.spacing(9 / 4),
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#000000',
    padding: '0.5rem 0 0.2rem 0',
    maxWidth: '75%',
  },
  dateTitleText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    maxWidth: '100%',
  },
  arrowDisable: {
    opacity: 0.3,
    cursor: 'not-allowed',
  }
});

const formats = {
  dateFormat: 'D',
  dayFormat: (date, culture, localizer) => {
    const weekday = localizer.format(date, 'ddd', culture);
    const month = localizer.format(date, 'MMM', culture);
    const d = localizer.format(date, 'D', culture);
    return `${weekday}, ${month} ${d}`;
  },
  weekdayFormat: 'ddd',
  timeGutterFormat: 'h a',
  nonFullHourTimeGutterFormat: 'h:mm a',
}

const isScheduled = ['scheduled', 'modified'];
// NOTE: this should match date format for dayShifts returned in staff from server
const DATE_FORMAT_FOR_CALENDAR = 'YYYY-MM-DD'

const getPeriodTimesByView = (options) => {
  const {
    currentView,
    currentDate,
    localizer,
    moment,
  } = options;

  switch (currentView) {
    case Views.MONTH: {
      const start = moment(currentDate).startOf('month').startOf('day');
      const end = moment(currentDate).endOf('month').endOf('day');

      return [start, end];
    }
    case Views.WEEK: {
      const firstOfWeek = localizer.startOfWeek();
      const weekModifier = (firstOfWeek === 0) ? 'week' : 'isoWeek';
      const start = moment(currentDate).startOf(weekModifier);
      const end = moment(currentDate).endOf(weekModifier);

      return [start, end];
    }
    case Views.DAY: {
      const start = moment(currentDate).startOf('day');
      const end = moment(currentDate).endOf('day');

      return [start, end];
    }
    default:
      console.log('Unknown view');
      return [];
  }
};

const getPeriodDatesByView = (options) => {
  const {
    currentView,
    currentDate,
    moment,
    localizer,
  } = options;

  switch (currentView) {
    case Views.MONTH: {
      const start = moment(currentDate).startOf('month');
      const end = moment(currentDate).endOf('month');

      return [start, end];
    }
    case Views.WEEK: {
      const firstOfWeek = localizer.startOfWeek();
      const weekModifier = (firstOfWeek === 0) ? 'week' : 'isoWeek';
      const start = moment(currentDate).startOf(weekModifier);
      const end = moment(currentDate).endOf(weekModifier);

      return [start, end];
    }
    case Views.DAY: {
      const start = moment(currentDate).startOf('day');

      return [start, start];
    }
    default:
      console.log('Unknown view');
      return [];
  }
};

const getBalancedDate = (startTime, endTime) => {
  const firstDate = moment(startTime);
  const secondDate = moment(endTime);
  let modifiedSecondDate = secondDate.clone();

  if (firstDate.month() !== secondDate.month()) {
    modifiedSecondDate = modifiedSecondDate.month(firstDate.month());
  }
  if (firstDate.year() !== secondDate.year()) {
    modifiedSecondDate = modifiedSecondDate.year(firstDate.year());
  }

  if (firstDate.date() !== secondDate.date()) {
    modifiedSecondDate = modifiedSecondDate.date(firstDate.date());
  }
  return modifiedSecondDate.toDate()
}

const eventsInitializeMap = {
  [calendarEventTypes.appointment]: (appointment, moment) => {
    const {
      clientName,
      startTime,
      endTime,
      fullStartTime,
      fullEndTime,
      staffId,
      serviceColor,
    } = appointment;

    const start = moment(startTime).toDate();
    const end = moment(endTime).toDate();

    const fullStart = moment(fullStartTime).toDate();
    const fullEnd = moment(fullEndTime).toDate();

    const color = serviceColor || '#FD9B5B';

    return {
      ...appointment,
      color,
      start,
      end,
      fullStart,
      fullEnd,
      title: clientName,
      backgroundColor: buildRGBforBackgroundColor(color),
      paddingColor: buildRGBforPaddingColor(color),
      resourceId: staffId,
    };
  },
  [calendarEventTypes.busyTime]: (busyTime, moment) => {
    const { startTime, endTime, staffId } = busyTime;

    const start = moment(startTime).toDate();
    const end = moment(endTime).toDate();

    return {
      ...busyTime,
      start,
      end,
      color: grey[600],
      backgroundColor: grey[100],
      resourceId: staffId,
    };
  },
};
const initializeEvents = (events, moment) => {
  return events.map((event) => {
    const initialize = eventsInitializeMap[event.type];
    return initialize(event, moment);
  });
};

const convertTimeToDuration = (start, end, moment) => {
  return timeDiffInMinutes(end, {
    moment,
    startDate: start,
  });
};

const prepareAppointment = ({ event, start, end, resourceId, moment }) => {
  const {
    id,
    paddingBefore,
    paddingAfter,
    resourceItemId,
  } = event;
  const startTime = moment(start);
  const endTime = moment(end);

  return {
    id,
    paddingBefore,
    paddingAfter,
    resourceItemId,
    staffId: resourceId,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    duration: convertTimeToDuration(startTime, endTime, moment),
    utcOffset: startTime.utcOffset(),
  };
};

const prepareBusyTime = ({ event, start, end, resourceId, moment }) => {
  const startTime = moment(start);
  const endTime = moment(end);

  return {
    id: event.id,
    staffId: resourceId,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    duration: convertTimeToDuration(startTime, endTime, moment),
    utcOffset: startTime.utcOffset(),
  };
};

const eventStyles = (event) => {
  const backgroundColor = event.backgroundColor || 'rgb(88, 203, 125)';

  let borderStylesForChangeMode = {};
  if (event.changeMode) {
    borderStylesForChangeMode = {
      border: `2px dashed ${event.color}`,
    };
  }

  return {
    style: {
      backgroundColor,
      border: 'none',
      borderRadius: 0,
      padding: 0,
      width: '100%',
      color: event.color,
      outline: 'none',
      ...borderStylesForChangeMode,
    },
  };
};

const slotStyles = ({ staff, location, step, max, moment }) => {
  const { beginTime, endTime } = location;

  return (start, resourceId) => {
    if (!resourceId) {
      return {};
    }

    const isTimeIncludedInLocationWorkingHours = isSelectedTimeIncludedInLocationWorkingHours({
      start,
      beginTime,
      endTime,
      step,
      moment,
    });
    // Leave border for the end time by using the second part of the condition
    if (!isTimeIncludedInLocationWorkingHours && start.getMinutes() !== max.getMinutes()) {
      return {
        style: {
          backgroundColor: '#E6E8E9',
          borderTopColor: '#E6E8E9',
        },
      };
    }

    const { dayShifts } = staff.find(({ id }) => id === resourceId);
    const isTimeIncludedInStaffShift = isSelectedTimeIncludedInStaffWorkingHours({
      start,
      dayShifts,
      step,
      moment,
    });
    if (!isTimeIncludedInStaffShift || !isTimeIncludedInLocationWorkingHours) {
      return {
        style: {
          backgroundColor: '#E6E8E9',
        },
      };
    }

    return {};
  };
};

const calculateTimeSlotsInHour = (step) => 60 / step;

const getDateByMinutesOffset = (date, time, moment) => {
  const beginningOfDay = moment(date).startOf('day');
  return setHoursAndMinutesForDate(beginningOfDay, time, { moment }).toDate();
};

const allowedViews = [Views.DAY, Views.WEEK, Views.MONTH];
const getCurrentView = (currentView, queryView) => {
  return allowedViews.includes(queryView) ? queryView : currentView;
};

const getCurrentDate = (queryDate, moment) => {
  const currentDate = moment();

  const strictMode = true;
  const selectedDate = moment(queryDate, 'YYYY-MM-DD', strictMode);

  if (!selectedDate.isValid()) {
    return currentDate.toDate();
  }

  selectedDate.hours(currentDate.hours());
  selectedDate.minutes(currentDate.minutes());
  selectedDate.seconds(currentDate.seconds());

  return selectedDate.toDate();
};

const getLocation = (locations, queryLocationId) => {
  if (locations.length === 0) {
    return '';
  }

  const selectedLocation = locations.find(({ id }) => id === queryLocationId);
  const defaultLocation = locations.find((location) => {
    return location.isDefaultLocationForCurrentStaff;
  });

  return selectedLocation || defaultLocation || locations[0];
};

const allRosteredStaffOption = { label: 'All Rostered', value: '' };

const getStaffId = (options) => {
  const {
    staff,
    queryStaffId,
    currentStaffId,
    isStaff,
    isMobileScreen,
    currentView
  } = options;
  const isMonthView = currentView === Views.MONTH

  if (staff.length === 0) {
    return allRosteredStaffOption.value;
  }

  if (queryStaffId) {
    const selectedStaff = staff.find(({ id }) => id === queryStaffId);
    if (selectedStaff) {
      return selectedStaff.id;
    }
  }

  if (isStaff) {
    const currentStaff = staff.find(({ id }) => id === currentStaffId);
    return currentStaff
      ? currentStaff.id
      : (isMobileScreen || isMonthView) ? staff[0].id : currentStaffId;
  }

  // On mobile resolutions and in Month view 'All Rostered' filter is hidden
  if (!isStaff && (isMobileScreen || isMonthView)) {
    return staff[0].id
  }
  return allRosteredStaffOption.value;
};

const addEventToEvents = (events, event, selectedLocationId) => {
  const isSameLocation = (
    !selectedLocationId
    || selectedLocationId === event.locationId
  );

  return isSameLocation ? events.concat(event) : events;
};
const replaceEventInEvents = (events, event, selectedLocationId) => {
  const isSameLocation = (
    !selectedLocationId
    || selectedLocationId === event.locationId
  );

  return events.map((e) => (e.id === event.id && isSameLocation) ? event : e);
};
const removeEventFromEvents = (events, event, selectedLocationId) => {
  const isNotSameLocation = (
    !selectedLocationId
    || selectedLocationId !== event.locationId
  );

  return events.reduce((acc, e) => {
    if (e.id === event.id && isNotSameLocation) {
      return acc;
    }
    return [...acc, e];
  }, []);
};

const checkTimeChange = (oldTime, newTime) => {
  return !moment(oldTime).isSame(moment(newTime));
};
const checkAppointmentChange = (options) => {
  const { event, start, end, resourceId, locationId } = options;

  const isStartTimeChanged = checkTimeChange(event.start, start);
  const isEndTimeChanged = checkTimeChange(event.end, end);
  const isResourceChanged = resourceId && (event.resourceId !== resourceId);
  const isLocationChanged = locationId && (event.locationId !== locationId);
  return (
    isStartTimeChanged
    || isEndTimeChanged
    || isResourceChanged
    || isLocationChanged
  );
};

// Fix issue when two closest events look overlapsed even if phisically they don't
// The issue reflects our issue https://github.com/jquense/react-big-calendar/issues/909
// But the PR doesn't fix it https://github.com/jquense/react-big-calendar/pull/910
// Because it defines too high difference between two events
const customLayoutAlgorithm = ({ minimumStartDifference, ...restOptions }) => {
  return getStyledEvents({
    ...restOptions,
    minimumStartDifference: 5,
  });
};

// TODO: use useMediaQuery hook from MUI package when refactored to hooks
const getIsMobileScreen = () => window.innerWidth < 600

const MonthCalendar = props => {
  const {events, staff, selectedStaffId} = props
  const staffEvents = useMemo(() => events.filter(ev => ev.staffId === selectedStaffId), [events, selectedStaffId])
  const availableDaysSetForMonthView = useMemo(() => {
    const {dayShifts = []} = staff.find(({id}) => id === selectedStaffId) || {}
    // Cache days where events are set and staff is available to optimize styling for month view
    const availableDaysSetForMonthView = staffEvents.reduce((acc, ev) => {
      // Format times in business time
      acc.add(moment(ev.start).format(DATE_FORMAT_FOR_CALENDAR))
      acc.add(moment(ev.end).format(DATE_FORMAT_FOR_CALENDAR))
      return acc
    }, new Set())
    dayShifts.forEach(({type, date}) => {
      if ([dayShiftTypes.scheduled.type, dayShiftTypes.modified.type].includes(type)) {
        availableDaysSetForMonthView.add(date)
      }
    })
    return availableDaysSetForMonthView
  }, [staffEvents, staff, selectedStaffId])

  const monthViewDayPropGetter = useCallback(date => {
    return availableDaysSetForMonthView.has(moment(date).format(DATE_FORMAT_FOR_CALENDAR))
      ? null
      : {style: {backgroundColor: '#e6e6e6'}}
  }, [availableDaysSetForMonthView])

  return (
    <Calendar {...props} dayPropGetter={monthViewDayPropGetter} events={staffEvents} />
  )
}

class Index extends Component {
  constructor(props) {
    super(props);

    const slotStep = 15;

    const guessTZ = moment.tz.guess();
    moment.tz.setDefault(guessTZ);

    this.state = {
      slotStep,
      anchorEl: null,
      isLinkPopoverOpened: false,
      position: { top: 0, left: 0 },
      currentTime: null,
      currentDate: moment().toDate(),
      clickedSlotDate: null,
      clickedSlotStaffId: '',
      currentView: Views.DAY,
      events: [],
      isAppointmentDialogOpened: false,
      isBusyTimeDialogOpened: false,
      selectedEvent: null,
      selectedLocation: {},
      selectedStaffId: allRosteredStaffOption.value,
      staff: [],
      changeAppointmentMode: false,
      eventForChanging: null,
      calendarStateBeforeChangeMode: {},
      startDate: '',
      endDate: '',
      slotsInOneHour: calculateTimeSlotsInHour(slotStep),
      isMobileScreen: getIsMobileScreen(),
      isAllRosterStaff: false,
      isAllStaff: false,
      leftArrowDisabled: false,
      rightArrowDisabled: false,
      isDesktopAllStaff: false,
      prevStaffId: '',
    };
    this.handleOpenNewAppointmentDialog = this.handleOpenNewAppointmentDialog.bind(this);
    this.handleCloseAppointmentDialog = this.handleCloseAppointmentDialog.bind(this);
    this.handleOpenNewBusyTimeDialog = this.handleOpenNewBusyTimeDialog.bind(this);
    this.handleCloseBusyTimeDialog = this.handleCloseBusyTimeDialog.bind(this);
    this.changeSelectedDatetime = this.changeSelectedDatetime.bind(this);
    this.changeCurrentView = this.changeCurrentView.bind(this);
    this.onClickMonthDate = this.onClickMonthDate.bind(this);
    this.handleOpenEditAppointmentDialog = this.handleOpenEditAppointmentDialog.bind(this);
    this.handleOpenEditBusyTimeDialog = this.handleOpenEditBusyTimeDialog.bind(this);
    this.handleOpenEditEventDialog = this.handleOpenEditEventDialog.bind(this);
    this.onClickLocation = this.onClickLocation.bind(this);
    this.onClickStaff = this.onClickStaff.bind(this);
    this.onClickOpenPopover = this.onClickOpenPopover.bind(this);
    this.onClickClosePopover = this.onClickClosePopover.bind(this);
    this.onUpdateEvent = this.onUpdateEvent.bind(this);
    this.handleUpdateMovedAppointment = this.handleUpdateMovedAppointment.bind(this);
    this.handleUpdateMovedBusyTime = this.handleUpdateMovedBusyTime.bind(this);
    this.handleUpdateMovedEvent = this.handleUpdateMovedEvent.bind(this);
    this.handleUpdateResizedEvent = this.handleUpdateResizedEvent.bind(this);
    this.cancelAppointmentCallback = this.cancelAppointmentCallback.bind(this);
    this.handleStartChangingAppointment = this.handleStartChangingAppointment.bind(this);
    this.handleApplyChangingAppointment = this.handleApplyChangingAppointment.bind(this);
    this.handleCancelChangingAppointment = this.handleCancelChangingAppointment.bind(this);
    this.handleClickOnEventInChangeMode = this.handleClickOnEventInChangeMode.bind(this);
    this.handleClickOnAppointmentInChangeMode = this.handleClickOnAppointmentInChangeMode.bind(this);
    this.handleClickOnBusyTimeInChangeMode = this.handleClickOnBusyTimeInChangeMode.bind(this);
    this.handleMoveEventInChangeMode = this.handleMoveEventInChangeMode.bind(this);
    this.handlePickTimeInChangeMode = this.handlePickTimeInChangeMode.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.setAllStaff = this.setAllStaff.bind(this);
    this.setAllRosterStaff = this.setAllRosterStaff.bind(this);
    this.goToPrevStaff = this.goToPrevStaff.bind(this);
    this.goToNextStaff = this.goToNextStaff.bind(this);
    this.getStaffForCalendar = this.getStaffForCalendar.bind(this);

    // eslint-disable-next-line react/destructuring-assignment
    this.props.handleStartLoading()
  }

  async componentDidMount() {
    const { isMobileScreen, currentView: prevCurrentView } = this.state;
    const {
      queryDate,
      queryView,
      queryLocationId,
      queryStaffId,
      auth,
      router,
      loadBusiness,
      loadLocationsForCalendar,
      loadCancellationReasons,
      handleStopLoading,
      loadGetStartedStep
    } = this.props;
    const { currentStaff, userId } = auth;
    const { id: currentStaffId, isStaff, isOwner, isSupport } = currentStaff;
    const { payload: { timezone } } = await loadBusiness();
    moment.tz.setDefault(timezone);

    const currentView = getCurrentView(prevCurrentView, queryView);
    const currentDate = getCurrentDate(queryDate, moment);
    const [startDate, endDate] = getPeriodDatesByView({
      currentView,
      currentDate,
      timezone,
      moment,
      localizer,
    });
    const [startDatetime, endDatetime] = getPeriodTimesByView({
      currentView,
      currentDate,
      localizer,
      timezone,
      moment,
    });

    const { payload: locations } = await loadLocationsForCalendar({
      currentStaffId,
      startDatetime: startDatetime.toISOString(),
      endDatetime: endDatetime.toISOString(),
    });
    const selectedLocation = getLocation(locations, queryLocationId);

    const eventsOptions = {
      startDatetime: startDatetime.toISOString(),
      endDatetime: endDatetime.toISOString(),
      locationId: selectedLocation.id,
    };
    let staffOptions = {};
    switch(currentView) {
      case Views.DAY: {
        staffOptions = {
          startDate: getDate(startDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          weekday: getWeekday(startDate),
          locationId: selectedLocation.id,
        };
        break;
      }
      case Views.WEEK: {
        staffOptions = {
          startDate: getDate(startDate),
          endDate: getDate(endDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          locationId: selectedLocation.id,
        };
        break;
      }
      default: {
        staffOptions = {
          startDate: getDate(startDate),
          endDate: getDate(endDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          locationId: selectedLocation.id,
        };
      }
    }
    const [newEvents, staff] = await Promise.all([
      BookingApi.fetchEventsForCalendar(eventsOptions, auth),
      StaffApi.fetchStaffForCalendar(staffOptions, auth)
    ]);

    let selectedStaffId = getStaffId({
      staff,
      queryStaffId,
      currentStaffId,
      isStaff,
      isMobileScreen,
      currentView
    });

    if (!isMobileScreen && (isOwner || isSupport) && !queryStaffId) {
      selectedStaffId = '';
    }

    await loadGetStartedStep(userId)

    loadCancellationReasons();

    const calendarSettings = await SettingsApi.fetchCalendarSettings(auth);
    const slotStep = calendarSettings.intervals;

    const queryAttrs = prepareQueryOptions({
      selectedStaffId,
      routerQuery: router.query,
      date: getDate(startDate),
      view: currentView,
      location: selectedLocation.id,
      staff: selectedStaffId,
    });
    router.push({ query: queryAttrs }).then(() => {
      keepQueryAttrsInBrowser(queryAttrs);
    });
    const currentStaffIndex = staff.findIndex(({ id }) => id === selectedStaffId);
    const arrows = !staff.length || staff.length <= 1
      ? {leftArrowDisabled: true, rightArrowDisabled: true}
      : currentStaffIndex === 0
        ? {leftArrowDisabled: true}
        : currentStaffIndex === staff.length - 1
          ? {rightArrowDisabled: true}
          : {}
    this.setState({
      slotStep,
      calendarSettings,
      staff,
      startDate,
      endDate,
      currentView,
      currentDate,
      selectedLocation,
      selectedStaffId,
      prevStaffId: selectedStaffId,
      events: initializeEvents(newEvents, moment),
      slotsInOneHour: calculateTimeSlotsInHour(slotStep),
      isAllRosterStaff: !!isMobileScreen,
      // isAllStaff: !!(isMobileScreen && !isStaff),
      ...arrows
    }, handleStopLoading);

    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    moment.tz.setDefault();
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    const {isMobileScreen, selectedStaffId, staff, currentView} = this.state
    const newIsMobileScreen = getIsMobileScreen()
    if (isMobileScreen !== newIsMobileScreen) {
      this.setState({
        isMobileScreen: newIsMobileScreen
      }, () => {
        if (newIsMobileScreen && selectedStaffId === allRosteredStaffOption.value) {
          const {auth: {currentStaff}} = this.props
          const {id: currentStaffId, isStaff} = currentStaff
          const staffId = getStaffId({staff, currentStaffId, isStaff, isMobileScreen: newIsMobileScreen, currentView})
          this.onClickStaff(staffId)()
        }
      })
    }
  }

  handleOpenNewAppointmentDialog() {
    this.setState({
      isAppointmentDialogOpened: true,
      isLinkPopoverOpened: false,
    })
  }

  handleOpenEditAppointmentDialog(event) {
    this.setState({
      isAppointmentDialogOpened: true,
      isLinkPopoverOpened: false,
      selectedEvent: event,
      clickedSlotDate: null,
      clickedSlotStaffId: event.resourceId,
    });
  }

  handleOpenEditBusyTimeDialog(event) {
    this.setState({
      isBusyTimeDialogOpened: true,
      isLinkPopoverOpened: false,
      selectedEvent: event,
      clickedSlotDate: null,
      clickedSlotStaffId: event.resourceId,
    });
  }

  handleOpenEditEventDialog(event) {
    if (event.type === calendarEventTypes.appointment) {
      this.handleOpenEditAppointmentDialog(event);
      return;
    }
    const {auth: {currentStaff = {}}} = this.props;
    const { isOwner, id: currentStaffId, access = []} = currentStaff;
    const isAllow = isOwner || access.includes('staffBusyTimeAccess') || event.staffId === currentStaffId;
    if (!isAllow) {
      return;
    }
    this.handleOpenEditBusyTimeDialog(event);
  }

  handleCloseAppointmentDialog() {
    this.setState({
      isAppointmentDialogOpened: false,
      selectedEvent: null,
      clickedSlotDate: null,
      clickedSlotStaffId: '',
    });
  }

  handleStartChangingAppointment() {
    const {
      events,
      selectedEvent,
      currentDate,
      currentView,
      selectedLocation,
      selectedStaffId,
    } = this.state;

    const eventForChanging = { ...selectedEvent, changeMode: true };
    const newEvents = replaceEventInEvents(events, eventForChanging);

    this.setState({
      eventForChanging,
      events: newEvents,
      isAppointmentDialogOpened: false,
      changeAppointmentMode: true,
      calendarStateBeforeChangeMode: {
        currentDate,
        currentView,
        selectedLocation,
        selectedStaffId,
      },
    });
  }

  async handleApplyChangingAppointment() {
    const {
      eventForChanging: {
        changeMode,
        start,
        end,
        resourceId,
        locationId,
        ...event
      },
      selectedEvent,
      selectedLocation,
      events,
      staff,
    } = this.state;
    const { business: { timezone },
      auth: { currentStaff },
      handleStartLoading,
      updateAppointment,
      handleStopLoading,
      handleDisplayFlashMessage
    } = this.props;

    const isAppointmentChanged = checkAppointmentChange({
      start,
      end,
      resourceId,
      locationId,
      event: selectedEvent,
    });
    if (!isAppointmentChanged) {
      const newEvents = replaceEventInEvents(events, selectedEvent);
      this.setState({
        isAppointmentDialogOpened: false,
        changeAppointmentMode: false,
        eventForChanging: null,
        selectedEvent: null,
        events: newEvents,
        calendarStateBeforeChangeMode: {},
      });
      return;
    }

    handleStartLoading();

    const appointment = prepareAppointment({
      event,
      start,
      end,
      resourceId,
      moment,
    });
    const data = {
      appointment: {
        ...appointment,
        locationId: selectedLocation.id,
      },
      siteUrl: window.location.origin,
      currentClientTime: getCurrentClientTime(),
    };

    try {
      const options = { currentStaffId: currentStaff.id };
      await updateAppointment(appointment.id, data, options);

      const updatedEvent = {
        ...event,
        resourceId,
        start,
        end,
        locationId: selectedLocation.id,
      };
      const newEvents = replaceEventInEvents(events, updatedEvent);

      this.setState({
        isAppointmentDialogOpened: false,
        changeAppointmentMode: false,
        eventForChanging: null,
        selectedEvent: null,
        events: newEvents,
        calendarStateBeforeChangeMode: {},
      }, () => {
        const selectedStaff = staff.find((staff) => staff.id === resourceId);
        const areTimeBordersIncluded = areEventBordersIncludedInStaffWorkingHours({
          start,
          end,
          moment,
          dayShifts: selectedStaff.dayShifts,
        });

        handleStopLoading();
        if (areTimeBordersIncluded) {
          handleDisplayFlashMessage(
            'The appointment has been changed successfully',
          );
        } else {
          handleDisplayFlashMessage(
            'The appointment has been changed successfully but outside of staff\'s available working hours',
          );
        }
      });
    } catch (error) {
      if (error.name === 'OverlapAppointmentsByDurationError') {
        const message = composeOverlapByDurationErrorMessage(
          error.body,
          timezone,
        );
        handleDisplayFlashMessage(message, 'error');
      }
      if (error.name === 'UseBusyResourceError') {
        const message = composeAppointmentBusyResourceErrorMessage(
          error.body,
          timezone,
        );
        handleDisplayFlashMessage(message, 'error');
      }
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      }
      console.log(error);
      handleStopLoading();
    }
  }

  async handleCancelChangingAppointment() {
    const {
      events,
      selectedEvent,
      calendarStateBeforeChangeMode: {
        currentDate,
        currentView,
        selectedLocation,
        selectedStaffId,
        isDesktopAllStaff,
        isAllStaff
      },
    } = this.state;
    const { business: { timezone }, auth, handleStartLoading, handleStopLoading } = this.props;
    handleStartLoading()

    const newEvents = replaceEventInEvents(events, selectedEvent);

    let options = {};
    const [startDate, endDate] = getPeriodDatesByView({
      currentView,
      currentDate,
      timezone,
      moment,
      localizer,
    });
    const [startDatetime, endDatetime] = getPeriodTimesByView({
      currentView,
      currentDate,
      localizer,
      timezone,
      moment,
    });
    switch(currentView) {
      case Views.DAY: {
        options = {
          startDate: getDate(startDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          weekday: getWeekday(startDate),
          locationId: selectedLocation.id,
        };
        break;
      }
      case Views.WEEK: {
        options = {
          startDate: getDate(startDate),
          endDate: getDate(endDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          locationId: selectedLocation.id,
        };
        break;
      }
      default: {
        options = {
          startDate: getDate(startDate),
          endDate: getDate(endDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          locationId: selectedLocation.id,
        };
      }
    }
    const staff = await StaffApi.fetchStaffForCalendar(options, auth, window.innerWidth > 600 ? isDesktopAllStaff : isAllStaff);

    this.setState({
      staff,
      currentDate,
      currentView,
      selectedLocation,
      selectedStaffId,
      isAppointmentDialogOpened: true,
      changeAppointmentMode: false,
      eventForChanging: null,
      events: newEvents,
      calendarStateBeforeChangeMode: {},
    }, handleStopLoading);
  }

  getStaffForCalendar = async (key) => {
    const { currentView: prevCurrentView, selectedLocation } = this.state;
    const {
      queryDate,
      queryView,
      // queryLocationId,
      auth,
      // loadLocationsForCalendar,
      handleStopLoading,
      handleDisplayFlashMessage,
      business: { timezone }
    } = this.props;
    // const { currentStaff } = auth;
    // const { id: currentStaffId } = currentStaff;

    const currentView = getCurrentView(prevCurrentView, queryView);
    const currentDate = getCurrentDate(queryDate, moment);
    const [startDate, endDate] = getPeriodDatesByView({
      currentView,
      currentDate,
      timezone,
      moment,
      localizer,
    });
    const [startDatetime, endDatetime] = getPeriodTimesByView({
      currentView,
      currentDate,
      localizer,
      timezone,
      moment,
    });

    // const { payload: locations } = await loadLocationsForCalendar({
    //   currentStaffId,
    //   startDatetime: startDatetime.toISOString(),
    //   endDatetime: endDatetime.toISOString(),
    // });
    // const selectedLocation = getLocation(locations, queryLocationId);

    const eventsOptions = {
      startDatetime: startDatetime.toISOString(),
      endDatetime: endDatetime.toISOString(),
      locationId: selectedLocation.id,
    };

    let staffOptions = {};
    switch(currentView) {
      case Views.DAY: {
        staffOptions = {
          startDate: getDate(startDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          weekday: getWeekday(startDate),
          locationId: selectedLocation.id,
        };
        break;
      }
      case Views.WEEK: {
        staffOptions = {
          startDate: getDate(startDate),
          endDate: getDate(endDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          locationId: selectedLocation.id,
        };
        break;
      }
      default: {
        staffOptions = {
          startDate: getDate(startDate),
          endDate: getDate(endDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          locationId: selectedLocation.id,
        };
      }
    }
    try {
      const [newEvents, staff] = await Promise.all([
        BookingApi.fetchEventsForCalendar(eventsOptions, auth),
        StaffApi.fetchStaffForCalendar(staffOptions, auth, key)
      ]);
      this.setState({events: initializeEvents(newEvents, moment)})
      handleStopLoading()
      return staff;
    } catch (error) {
      handleStopLoading()
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
      return [];
    }
  }

  handleClickOnAppointmentInChangeMode(event) {
    const { eventForChanging } = this.state;
    const { handleDisplayFlashMessage } = this.props;

    if (event.id === eventForChanging.id) {
      handleDisplayFlashMessage(
        'You can move the appointment in time and between staff'
      );
    } else {
      handleDisplayFlashMessage(
        'The time has already busy by other appointment', 'warning'
      );
    }
  }

  handleClickOnBusyTimeInChangeMode({ start, resourceId }) {
    const { events, eventForChanging: preventEventForChanging } = this.state;
    const { duration } = preventEventForChanging;
    const end = moment(start).add(duration, 'minutes').toDate();
    const eventForChanging = {
      ...preventEventForChanging,
      resourceId,
      start,
      end,
    };

    let newEvents;
    const foundEvent = events.find(({ id }) => id === eventForChanging.id);
    if (foundEvent) {
      newEvents = replaceEventInEvents(events, eventForChanging);
    } else {
      newEvents = addEventToEvents(events, eventForChanging);
    }

    this.setState({
      eventForChanging,
      clickedSlotDate: start,
      clickedSlotStaffId: resourceId,
      events: newEvents,
    });
  }

  handleClickOnEventInChangeMode(event) {
    if (event.type === calendarEventTypes.appointment) {
      this.handleClickOnAppointmentInChangeMode(event);
      return;
    }

    this.handleClickOnBusyTimeInChangeMode(event);
  }

  handleMoveEventInChangeMode({ event, start, end, resourceId }) {
    const {
      events,
      selectedLocation,
      staff,
      eventForChanging: { id },
    } = this.state;
    const { business: { timezone }, auth, handleDisplayFlashMessage } = this.props;

    if (event.id !== id) {
      handleDisplayFlashMessage(
        'You can only change selected appointment', 'error'
      );
      return;
    }

    const selectedStaff = staff.find((staff) => staff.id === resourceId);

    const areTimeBordersIncluded = areEventBordersIncludedInLocationWorkingHours({
      start,
      end,
      moment,
      beginTime: selectedLocation.beginTime,
      endTime: selectedLocation.endTime,
    });
    if (!areTimeBordersIncluded) {
      this.setState({ events }, () => {
        handleDisplayFlashMessage(
          'The appointment goes beyond of location\'s working hours', 'error'
        );
      });
      return;
    }

    const hasActiveService = doesStaffHaveActiveService(selectedStaff, event.serviceId);
    if (!hasActiveService) {
      this.setState({ events }, () => {
        handleDisplayFlashMessage(
          'The staff doesn\'t have active service', 'error'
        );
      });
      return;
    }

    const appointment = prepareAppointment({
      event,
      start,
      end,
      resourceId,
      moment,
    });
    const stringAppointment = JSON.stringify(appointment);
    // Do not use async/await
    // Need to update to old events in promise.catch block in case of failure and
    // update to new events outside the promise in case of success
    // To avoid jumping event while dragging it in case of success
    BookingApi.runAppointmentChecks(stringAppointment, auth)
      .catch((error) => {
        if (error.name === 'OverlapAppointmentsByDurationError') {
          const message = composeOverlapByDurationErrorMessage(
            error.body,
            timezone,
          );
          this.setState({ events }, () => {
            handleDisplayFlashMessage(message, 'error');
          });
          return;
        }
        if (error.name === 'UseBusyResourceError') {
          const message = composeAppointmentBusyResourceErrorMessage(
            error.body,
            timezone,
          );
          this.setState({ events }, () => {
            handleDisplayFlashMessage(message, 'error');
          });
          return;
        }
        if (error?.message?.includes('Session is expired')) {
          this.setState({ events }, () => {
            handleDisplayFlashMessage('Session is expired, refresh the page please', 'error');
          });
          return;
        }
        console.log(error);
      });
    // ----------------------------------------------------

    const eventForChanging = {
      ...event,
      resourceId,
      start,
      end,
      locationId: selectedLocation.id,
    };
    const newEvents = replaceEventInEvents(events, eventForChanging);

    this.setState({
      eventForChanging,
      events: newEvents,
    });
  }

  handlePickTimeInChangeMode = (step) => async (event) => {
    const { box, start, resourceId } = event;

    if (box === undefined) {
      return;
    }

    const {
      events,
      staff,
      selectedLocation: { beginTime, endTime, ...selectedLocation },
      eventForChanging: { duration, serviceId, isChangeable },
    } = this.state;
    const { business: { timezone }, auth, handleDisplayFlashMessage } = this.props;

    if (!isChangeable) {
      const message = getFlashMessageWhileMovingAppointment(event);
      handleDisplayFlashMessage(message, 'error');
      return;
    }

    const isTimeIncluded = isSelectedTimeIncludedInLocationWorkingHours({
      start,
      beginTime,
      endTime,
      step,
      moment,
    });
    if (!isTimeIncluded) {
      handleDisplayFlashMessage(
        'You cannot pick time outside of the location working hours', 'error'
      );
      return;
    }

    const end = moment(start).add(duration, 'minutes').toDate();

    const areTimeBordersIncluded = areEventBordersIncludedInLocationWorkingHours({
      start,
      end,
      beginTime,
      endTime,
      moment,
    });
    if (!areTimeBordersIncluded) {
      handleDisplayFlashMessage(
        'The appointment goes beyond of location\'s working hours', 'error'
      );
      return;
    }

    const selectedStaff = staff.find((staff) => staff.id === resourceId);
    const hasActiveService = doesStaffHaveActiveService(selectedStaff, serviceId);
    if (!hasActiveService) {
      handleDisplayFlashMessage(
        'The staff doesn\'t have active service', 'error'
      );
      return;
    }

    const eventForChanging = {
      // eslint-disable-next-line react/destructuring-assignment
      ...this.state.eventForChanging,
      resourceId,
      start,
      end,
      locationId: selectedLocation.id,
    };

    try {
      const appointment = prepareAppointment({
        start,
        end,
        resourceId,
        moment,
        event: eventForChanging,
      });
      const stringAppointment = JSON.stringify(appointment);

      await BookingApi.runAppointmentChecks(stringAppointment, auth);
    } catch (error) {
      if (error.name === 'OverlapAppointmentsByDurationError') {
        const message = composeOverlapByDurationErrorMessage(
          error.body,
          timezone,
        );
        handleDisplayFlashMessage(message, 'error');
        return;
      }
      if (error.name === 'UseBusyResourceError') {
        const message = composeAppointmentBusyResourceErrorMessage(
          error.body,
          timezone,
        );
        handleDisplayFlashMessage(message, 'error');
        return;
      }
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error');
        return;
      }
      console.log(error);
    }

    let newEvents;
    const foundEvent = events.find(({ id }) => id === eventForChanging.id);
    if (foundEvent) {
      newEvents = replaceEventInEvents(events, eventForChanging);
    } else {
      newEvents = addEventToEvents(events, eventForChanging);
    }

    this.setState({
      eventForChanging,
      clickedSlotDate: start,
      clickedSlotStaffId: resourceId,
      events: newEvents,
    });
  };

  handleOpenNewBusyTimeDialog() {
    const {auth: {currentStaff = {}}, handleDisplayFlashMessage} = this.props;
    const { clickedSlotStaffId } = this.state;
    const { isOwner, id: currentStaffId, access = []} = currentStaff;
    const isAllow = isOwner || access.includes('staffBusyTimeAccess') || clickedSlotStaffId === currentStaffId;
    if (isAllow) {
      this.setState({
        isBusyTimeDialogOpened: true,
        isLinkPopoverOpened: false,
      })
    } else {
      this.setState({
        isLinkPopoverOpened: false,
      })
      handleDisplayFlashMessage('You cannot add a busy time to another user.', 'error')
    }
  }

  handleCloseBusyTimeDialog() {
    this.setState({
      isBusyTimeDialogOpened: false,
      selectedEvent: null,
      clickedSlotDate: null,
      clickedSlotStaffId: '',
    });
  }

  onClickOpenPopover = (step) => (event) => {
    const {
      box = {},
      bounds = {},
      start,
      resourceId,
    } = event;

    const container = { ...box, ...bounds };

    const { staff, selectedLocation: { beginTime, endTime } } = this.state;
    const { handleDisplayFlashMessage } = this.props;

    const selectedStaff = staff.find(({ id }) => id === resourceId);
    if (selectedStaff.isArchived) {
      handleDisplayFlashMessage(
        'Adding appointment blocked because the Staff was archived', 'error'
      );
      return;
    } else if (!selectedStaff.visibleInCalendar) {
      handleDisplayFlashMessage(
        'Adding appointment blocked because the Staff was set up as invisible for calendar', 'error'
      );
      return;
    }

    const selectedTime = moment(start);

    const isTimeIncluded = isSelectedTimeIncludedInLocationWorkingHours({
      start,
      beginTime,
      endTime,
      step,
      moment,
    });
    if (!isTimeIncluded) {
      this.setState({ isLinkPopoverOpened: false });
      return;
    }

    const top = container.y;
    const left = container.x;

    this.setState(({ isLinkPopoverOpened }) => ({
      currentTime: selectedTime.format('LT'),
      isLinkPopoverOpened: true,
      position: {
        ...container,
        top,
        left,
      },
      clickedSlotDate: start,
      clickedSlotStaffId: resourceId,
    }));
  };

  onClickClosePopover() {
    this.setState({ isLinkPopoverOpened: false });
  }

  async changeSelectedDatetime(date) {
    const {
      currentView,
      selectedLocation,
      eventForChanging,
      selectedStaffId,
      isDesktopAllStaff,
      isAllStaff,
      prevStaffId
    } = this.state;
    const { business: { timezone }, auth, router, handleStartLoading, handleStopLoading, handleDisplayFlashMessage } = this.props;
    try {
      handleStartLoading()
      const currentDate = date;
      const [startDatetime, endDatetime] = getPeriodTimesByView({
        currentView,
        currentDate,
        localizer,
        timezone,
        moment,
      });
      const eventsOptions = {
        startDatetime: startDatetime.toISOString(),
        endDatetime: endDatetime.toISOString(),
        locationId: selectedLocation.id,
      };
      let staffOptions;
      const [startDate, endDate] = getPeriodDatesByView({
        currentView,
        currentDate,
        timezone,
        moment,
        localizer,
      });
      switch(currentView) {
        case Views.DAY: {
          staffOptions = {
            startDate: getDate(startDate),
            startDatetime: startDatetime.toISOString(),
            endDatetime: endDatetime.toISOString(),
            weekday: getWeekday(startDate),
            locationId: selectedLocation.id,
          };
          break;
        }
        case Views.WEEK: {
          staffOptions = {
            startDate: getDate(startDate),
            endDate: getDate(endDate),
            startDatetime: startDatetime.toISOString(),
            endDatetime: endDatetime.toISOString(),
            locationId: selectedLocation.id,
          };
          break;
        }
        default: {
          staffOptions = {
            startDate: getDate(startDate),
            endDate: getDate(endDate),
            startDatetime: startDatetime.toISOString(),
            endDatetime: endDatetime.toISOString(),
            locationId: selectedLocation.id,
          };
        }
      }
      // eslint-disable-next-line prefer-const
      let [newEvents, staff] = await Promise.all([
        BookingApi.fetchEventsForCalendar(eventsOptions, auth),
        StaffApi.fetchStaffForCalendar(staffOptions, auth, window.innerWidth > 600 ? isDesktopAllStaff : isAllStaff)
      ]);
      newEvents = initializeEvents(newEvents, moment);

      if (eventForChanging) {
        const foundEvent = newEvents.find(({ id }) => id === eventForChanging.id);
        if (foundEvent) {
          newEvents = removeEventFromEvents(
            newEvents,
            eventForChanging,
            selectedLocation.id,
          );
          newEvents = replaceEventInEvents(
            newEvents,
            eventForChanging,
            selectedLocation.id,
          );
        } else {
          newEvents = addEventToEvents(
            newEvents,
            eventForChanging,
            selectedLocation.id,
          );
        }
      }

      let newStaffId = selectedStaffId;
      if (selectedStaffId !== allRosteredStaffOption.value) {
        const selectedStaff = staff.find(({ id }) => id === selectedStaffId);
        newStaffId = selectedStaff ? selectedStaff.id : allRosteredStaffOption.value;
      }

      if (!newStaffId && window.innerWidth < 600 && staff.length) {
        const selectedStaff = staff.find(({ id }) => id === prevStaffId);
        newStaffId = selectedStaff?.id || '';
      }

      if (window.innerWidth > 600 && isDesktopAllStaff) {
        newStaffId = undefined
      }

      const queryAttrs = prepareQueryOptions({
        view: currentView,
        selectedStaffId: newStaffId,
        routerQuery: router.query,
        date: getDate(startDate),
        staff: newStaffId,
      });
      router.push({ query: queryAttrs }).then(() => {
        keepQueryAttrsInBrowser(queryAttrs);
      });

      const currentStaffIndex = staff.findIndex(({ id }) => id === newStaffId);
      const arrows = !staff.length || staff.length <= 1
        ? {leftArrowDisabled: true, rightArrowDisabled: true}
        : currentStaffIndex === 0
          ? {leftArrowDisabled: true, rightArrowDisabled: staff.length === 1}
          : currentStaffIndex === staff.length - 1
            ? {rightArrowDisabled: true, leftArrowDisabled: staff.length === 1}
            : {leftArrowDisabled: false, rightArrowDisabled: false}

      this.setState({
        staff,
        currentDate,
        events: newEvents,
        selectedStaffId: newStaffId,
        prevStaffId: newStaffId?.length ? newStaffId : prevStaffId,
        ...arrows
      }, handleStopLoading);
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
      handleStopLoading();
    }
  }

  async changeCurrentView(view) {
    const {
      currentDate,
      selectedLocation,
      selectedStaffId,
      eventForChanging,
      isMobileScreen,
      isDesktopAllStaff,
      isAllStaff
    } = this.state;
    const { business: { timezone }, auth, router, handleStartLoading, handleStopLoading, handleDisplayFlashMessage } = this.props;
    handleStartLoading()
    const { currentStaff } = auth;
    const { id: currentStaffId, isStaff } = currentStaff;

    const [startDatetime, endDatetime] = getPeriodTimesByView({
      currentDate,
      localizer,
      timezone,
      moment,
      currentView: view,
    });
    const eventsOptions = {
      startDatetime: startDatetime.toISOString(),
      endDatetime: endDatetime.toISOString(),
      locationId: selectedLocation.id,
    };
    let staffOptions;
    const [startDate, endDate] = getPeriodDatesByView({
      currentDate,
      timezone,
      moment,
      localizer,
      currentView: view,
    });
    switch(view) {
      case Views.DAY: {
        staffOptions = {
          startDate: getDate(startDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          weekday: getWeekday(startDate),
          locationId: selectedLocation.id,
        };
        break;
      }
      case Views.WEEK: {
        staffOptions = {
          startDate: getDate(startDate),
          endDate: getDate(endDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          locationId: selectedLocation.id,
        };
        break;
      }
      default: {
        staffOptions = {
          startDate: getDate(startDate),
          endDate: getDate(endDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          locationId: selectedLocation.id,
        };
      }
    }
    try {
      // eslint-disable-next-line prefer-const
      let [newEvents, staff] = await Promise.all([
        BookingApi.fetchEventsForCalendar(eventsOptions, auth),
        StaffApi.fetchStaffForCalendar(staffOptions, auth, window.innerWidth > 600 ? isDesktopAllStaff : isAllStaff)
      ]);
      newEvents = initializeEvents(newEvents, moment);

      if (eventForChanging) {
        const foundEvent = newEvents.find(({ id }) => id === eventForChanging.id);
        if (foundEvent) {
          newEvents = removeEventFromEvents(
            newEvents,
            eventForChanging,
            selectedLocation.id,
          );
          newEvents = replaceEventInEvents(
            newEvents,
            eventForChanging,
            selectedLocation.id,
          );
        } else {
          newEvents = addEventToEvents(
            newEvents,
            eventForChanging,
            selectedLocation.id,
          );
        }
      }

      let newStaffId = selectedStaffId;
      if (selectedStaffId !== allRosteredStaffOption.value) {
        const selectedStaff = staff.find(({ id }) => id === selectedStaffId);
        newStaffId = selectedStaff ? selectedStaff.id : allRosteredStaffOption.value;
      }
      if (newStaffId === allRosteredStaffOption.value && view === Views.MONTH) {
        newStaffId = getStaffId({
          staff,
          currentStaffId,
          isStaff,
          isMobileScreen,
          currentView: view
        })
      }
      // if (window.innerWidth > 600 && isDesktopAllStaff) {
      //   newStaffId = undefined
      // }

      const queryAttrs = prepareQueryOptions({
        view,
        selectedStaffId: newStaffId,
        routerQuery: router.query,
        staff: newStaffId,
      });
      router.push({ query: queryAttrs }).then(() => {
        keepQueryAttrsInBrowser(queryAttrs);
      });

      this.setState({
        staff,
        currentView: view,
        selectedStaffId: newStaffId,
        events: newEvents,
      }, handleStopLoading);
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
      handleStopLoading();
    }
  }

  async onClickMonthDate(date, view) {
    // react-big-calendar puts a wrapper around onDrillDown prop so you can't just pass 3rd option
    // as a workaround we place staffId on date object
    const { staffId } = date;
    const {
      selectedLocation,
      eventForChanging,
      selectedStaffId,
      isDesktopAllStaff,
      isAllStaff
    } = this.state;
    const { business: { timezone }, auth, router, handleStartLoading, handleStopLoading } = this.props;
    handleStartLoading();
    const currentView = view;
    const currentDate = date;
    const isAll = window.innerWidth > 600 ? isDesktopAllStaff : isAllStaff

    const [startDatetime, endDatetime] = getPeriodTimesByView({
      localizer,
      timezone,
      moment,
      currentView,
      currentDate,
    });
    const eventsOptions = {
      startDatetime: startDatetime.toISOString(),
      endDatetime: endDatetime.toISOString(),
      locationId: selectedLocation.id,
    };
    const [startDate] = getPeriodDatesByView({
      currentView,
      currentDate,
      timezone,
      moment,
      localizer,
    });
    const staffOptions = {
      startDate: getDate(startDate),
      startDatetime: startDatetime.toISOString(),
      endDatetime: endDatetime.toISOString(),
      weekday: getWeekday(startDate),
      locationId: selectedLocation.id,
    };

    // eslint-disable-next-line prefer-const
    let [newEvents, staff] = await Promise.all([
      BookingApi.fetchEventsForCalendar(eventsOptions, auth),
      StaffApi.fetchStaffForCalendar(staffOptions, auth, isAll)
    ]);
    newEvents = initializeEvents(newEvents, moment);

    if (eventForChanging) {
      const foundEvent = newEvents.find(({ id }) => id === eventForChanging.id);
      if (foundEvent) {
        newEvents = removeEventFromEvents(
          newEvents,
          eventForChanging,
          selectedLocation.id,
        );
        newEvents = replaceEventInEvents(
          newEvents,
          eventForChanging,
          selectedLocation.id,
        );
      } else {
        newEvents = addEventToEvents(
          newEvents,
          eventForChanging,
          selectedLocation.id,
        );
      }
    }

    let newStaffId = selectedStaffId;
    if (selectedStaffId && selectedStaffId !== allRosteredStaffOption.value) {
      const selectedStaff = staff.find(({ id }) => id === selectedStaffId);
      newStaffId = selectedStaff ? selectedStaff.id : allRosteredStaffOption.value;
    }

    const queryAttrs = prepareQueryOptions({
      view: currentView,
      routerQuery: router.query,
      selectedStaffId: newStaffId,
      date: getDate(startDate),
      staff: newStaffId,
    });
    router.push({ query: queryAttrs }).then(() => {
      keepQueryAttrsInBrowser(queryAttrs);
    });
    this.setState({
      staff,
      currentView,
      currentDate,
      clickedSlotDate: currentDate,
      selectedStaffId: newStaffId,
      events: newEvents,
    }, () => {
      if (staffId) this.onClickStaff(staffId, isAll, getDate(startDate),)()
      handleStopLoading()
    });
  }

  onClickLocation = (location) => async () => {
    const {
      currentView,
      currentDate,
      eventForChanging,
      selectedStaffId,
      isMobileScreen,
      isAllRosterStaff,
      isAllStaff,
      isDesktopAllStaff
    } = this.state;
    const { business: { timezone }, auth, router, handleStartLoading, handleStopLoading, handleDisplayFlashMessage } = this.props;
    handleStartLoading()
    const { currentStaff } = auth;
    const { id: currentStaffId, isStaff } = currentStaff;
    const selectedLocation = location;

    const [startDatetime, endDatetime] = getPeriodTimesByView({
      currentDate,
      currentView,
      localizer,
      timezone,
      moment,
    });
    const eventsOptions = {
      startDatetime: startDatetime.toISOString(),
      endDatetime: endDatetime.toISOString(),
      locationId: selectedLocation.id,
    };

    let staffOptions;
    const [startDate, endDate] = getPeriodDatesByView({
      currentView,
      currentDate,
      timezone,
      moment,
      localizer,
    });
    switch(currentView) {
      case Views.DAY: {
        staffOptions = {
          startDate: getDate(startDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          weekday: getWeekday(startDate),
          locationId: selectedLocation.id,
        };
        break;
      }
      case Views.WEEK: {
        staffOptions = {
          startDate: getDate(startDate),
          endDate: getDate(endDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          locationId: selectedLocation.id,
        };
        break;
      }
      default: {
        staffOptions = {
          startDate: getDate(startDate),
          endDate: getDate(endDate),
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString(),
          locationId: selectedLocation.id,
        };
      }
    }

    try {
      // eslint-disable-next-line prefer-const
      let [newEvents, staff] = await Promise.all([
        BookingApi.fetchEventsForCalendar(eventsOptions, auth),
        StaffApi.fetchStaffForCalendar(staffOptions, auth, window.innerWidth > 600 ? isDesktopAllStaff : isAllStaff)
      ]);
      newEvents = initializeEvents(newEvents, moment);

      if (eventForChanging) {
        const foundEvent = newEvents.find(({ id }) => id === eventForChanging.id);
        if (foundEvent) {
          newEvents = removeEventFromEvents(
            newEvents,
            eventForChanging,
            selectedLocation.id,
          );
          newEvents = replaceEventInEvents(
            newEvents,
            eventForChanging,
            selectedLocation.id,
          );
        } else {
          newEvents = addEventToEvents(
            newEvents,
            eventForChanging,
            selectedLocation.id,
          );
        }
      }

      let newStaffId = selectedStaffId;
      const checkStaff = staff?.find(({ id }) => id === selectedStaffId);
      if ((!selectedStaffId || isEmpty(selectedStaffId) || !checkStaff) && isMobileScreen) {
        newStaffId = getStaffId({
          staff,
          currentStaffId,
          isStaff,
          isMobileScreen,
          currentView
        })
      }
      if (window.innerWidth > 600) {
        newStaffId = ''
      }
      const queryAttrs = prepareQueryOptions({
        selectedStaffId: newStaffId,
        view: currentView,
        routerQuery: router.query,
        location: selectedLocation.id,
        staff: newStaffId,
      });
      router.push({ query: queryAttrs }).then(() => {
        keepQueryAttrsInBrowser(queryAttrs);
      });
      const currentStaffIndex = staff.findIndex(({ id }) => id === newStaffId);
      const arrows = !staff.length || staff.length <= 1
        ? {leftArrowDisabled: true, rightArrowDisabled: true}
        : currentStaffIndex === 0
          ? {leftArrowDisabled: true, rightArrowDisabled: staff.length === 1}
          : currentStaffIndex === staff.length - 1
            ? {rightArrowDisabled: true, leftArrowDisabled: staff.length === 1}
            : {leftArrowDisabled: false, rightArrowDisabled: false}
      this.setState({
        staff,
        selectedLocation,
        selectedStaffId: newStaffId,
        prevStaffId: '',
        ...arrows,
        events: newEvents,
      }, () => {
        handleStopLoading()
        // if (window.innerWidth < 600) {
        //   if (isAllStaff) {
        //     this.setAllStaff(true)
        //   }
        //   if (!isAllStaff) {
        //     this.setAllRosterStaff(false)
        //   }
        // } else {
        //   if (isDesktopAllStaff) {
        //     this.setAllStaff(true)
        //   }
        //   if (!isDesktopAllStaff) {
        //     this.setAllRosterStaff(false)
        //   }
        // }
      });
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
      handleStopLoading();
    }
  }

  onClickStaff = (id, key, date) => async () => {
    const { staff, isMobileScreen, currentView, selectedLocation } = this.state;
    const { router } = this.props;
    // if (selectedStaffId === id) {
    //   return;
    // }
    let allStaff = staff
    if (!id || isEmpty(id)) {
      allStaff = await this.getStaffForCalendar(key)
    }

    const queryAttrs = prepareQueryOptions({
      view: currentView,
      selectedStaffId: id,
      routerQuery: router.query,
      ...(!isEmpty(selectedLocation) && selectedLocation?.id ? { location: selectedLocation.id } : {}),
      staff: id,
      ...(date && !isEmpty(date) ? { date } : {})
    });
    router.push({ query: queryAttrs }).then(() => {
      keepQueryAttrsInBrowser(queryAttrs);
    });

    this.setState({
      selectedStaffId: id || (isMobileScreen ? allStaff[0]?.id : id),
      prevStaffId: id || (isMobileScreen ? allStaff[0]?.id : id),
      ...(!id ? {staff: allStaff, isDesktopAllStaff: !!key } : {})
    });
  }

  handleUpdateMovedAppointment({ event, start, end, resourceId }) {
    const {
      handleDisplayFlashMessage,
      handleStartLoading,
      updateAppointment,
      handleStopLoading,
      business: { timezone },
      auth: { currentStaff }
    } = this.props;
    if (!event.isChangeable) {
      const message = getFlashMessageWhileMovingAppointment(event);
      handleDisplayFlashMessage(message, 'error');
      return;
    }
    const isAppointmentChanged = checkAppointmentChange({
      event,
      start,
      end,
      resourceId,
    });
    if (!isAppointmentChanged) {
      return;
    }

    const { events, selectedLocation, staff } = this.state;

    let areTimeBordersIncluded = areEventBordersIncludedInLocationWorkingHours({
      start,
      end,
      moment,
      beginTime: selectedLocation.beginTime,
      endTime: selectedLocation.endTime,
    });
    if (!areTimeBordersIncluded) {
      handleDisplayFlashMessage(
        'The appointment goes beyond of location\'s working hours', 'error'
      );
      return;
    }

    const selectedStaff = staff.find((staff) => staff.id === resourceId);
    const hasActiveService = doesStaffHaveActiveService(selectedStaff, event.serviceId);
    if (!hasActiveService) {
      handleDisplayFlashMessage(
        'The staff doesn\'t have active service', 'error'
      );
      return;
    }

    handleStartLoading();

    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end, resourceId };

    const newEvents = [...events];
    newEvents.splice(idx, 1, updatedEvent);

    const appointment = prepareAppointment({
      event,
      start,
      end,
      resourceId,
      moment,
    });
    const data = {
      appointment,
      siteUrl: window.location.origin,
      currentClientTime: getCurrentClientTime(),
    };
    const options = { currentStaffId: currentStaff.id };

    // Do not use async/await
    // To avoid jumping event while dragging it in case of success
    // Need to update to old events in promise.catch block in case of failure and
    // update to new events outside the promise in case of success
    updateAppointment(appointment.id, data, options)
      .then((response) => {
        areTimeBordersIncluded = areEventBordersIncludedInStaffWorkingHours({
          start,
          end,
          moment,
          dayShifts: selectedStaff.dayShifts,
        });

        handleStopLoading();

        if (areTimeBordersIncluded) {
          handleDisplayFlashMessage(
            'The appointment\'s time has been changed successfully',
          );
        } else {
          handleDisplayFlashMessage(
            'The appointment\'s time has been changed successfully but outside of staff\'s available working hours',
          );
        }
      })
      .catch((error) => {
        if (error.name === 'OverlapAppointmentsByDurationError') {
          const message = composeOverlapByDurationErrorMessage(
            error.body,
            timezone,
          );
          this.setState({ events }, () => {
            handleStopLoading();
            handleDisplayFlashMessage(message, 'error');
          });
          return;
        }
        if (error.name === 'UseBusyResourceError') {
          const message = composeAppointmentBusyResourceErrorMessage(
            error.body,
            timezone,
          );
          this.setState({ events }, () => {
            handleStopLoading();
            handleDisplayFlashMessage(message, 'error');
          });
          return;
        }
        if (error?.message?.includes('Session is expired')) {
          this.setState({ events }, () => {
            handleStopLoading();
            handleDisplayFlashMessage('Session is expired, refresh the page please', 'error');
          });
          return;
        }
        console.log('updateAppointment_err', error);
        handleStopLoading();
      });

    this.setState({ events: newEvents });
    // ----------------------------------
  }

  handleUpdateMovedBusyTime(options) {
    const { event, start, resourceId } = options;
    let {end: endTime} = options;
    const { events } = this.state;
    const { business: { timezone }, auth, handleStartLoading, handleStopLoading, handleDisplayFlashMessage } = this.props;
    if (endTime.valueOf() > moment(start).endOf('day').toDate().valueOf()) {
      endTime = moment().endOf('day').subtract(599, 'seconds').toDate()
    }
    const end = getBalancedDate(start, endTime) || endTime

    handleStartLoading();
    const newEvents = [...events];
    const updatedEvent = { ...event, start, end, resourceId };
    const index = events.indexOf(event);
    newEvents.splice(index, 1, updatedEvent);

    const preparedBusyTime = prepareBusyTime({
      event,
      start,
      end,
      resourceId,
      moment,
    });

    // Do not use async/await
    // To avoid jumping event while dragging it in case of success
    // Need to update to old events in promise.catch block in case of failure and
    // update to new events outside the promise in case of success
    BusyTimesApi.update(preparedBusyTime.id, preparedBusyTime, auth)
      .then((busyTime) => {
        if (!busyTime) {
          const events = newEvents.filter(({ id }) => id !== updatedEvent.id);
          this.setState({ events }, handleStopLoading);
          return;
        }
        const events = [...newEvents];
        const newStart = moment(busyTime.startTime).utcOffset(busyTime.utcOffset).toDate();
        const newEnd = moment(busyTime.endTime).utcOffset(busyTime.utcOffset).toDate();
        const event = {
          ...updatedEvent,
          ...busyTime,
          start: newStart,
          end: newEnd,
          resourceId,
        };
        const index = newEvents.indexOf(updatedEvent);
        events.splice(index, 1, event);
        this.setState({ events });

        handleStopLoading();
        handleDisplayFlashMessage(
          'The busy time has been changed successfully',
        );
      })
      .catch(err => {
        this.setState({ events });
        if (err?.message?.includes('Session is expired')) {
          handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
        } else {
          handleDisplayFlashMessage(err?.message || 'Unexpected error, please try again', 'error');
        }
        handleStopLoading();
        return err;
      });

    this.setState({ events: newEvents });
    // ----------------------------------
  }

  handleUpdateMovedEvent(movedData) {
    if (movedData.event.type === calendarEventTypes.appointment) {
      this.handleUpdateMovedAppointment(movedData);
      return;
    }
    const {auth: {currentStaff = {}}} = this.props;
    const { isOwner, id: currentStaffId, access = []} = currentStaff;
    const isAllow = isOwner || access.includes('staffBusyTimeAccess') || (movedData.event.staffId === currentStaffId && movedData.resourceId === currentStaffId);
    if (!isAllow) {
      return;
    }
    this.handleUpdateMovedBusyTime(movedData);
  }

  handleUpdateResizedEvent(resizedData) {
    if (resizedData.event.type === calendarEventTypes.appointment) {
      return;
    }
    const {auth: {currentStaff = {}}} = this.props;
    const { isOwner, id: currentStaffId, access = []} = currentStaff;
    const isAllow = isOwner || access.includes('staffBusyTimeAccess') || resizedData.event.staffId === currentStaffId;
    if (!isAllow) {
      return;
    }
    this.handleUpdateMovedBusyTime(resizedData);
  }

  async onUpdateEvent() {
    const {
      currentView,
      currentDate,
      selectedLocation,
    } = this.state;
    const {
      auth,
      business: { timezone },
    } = this.props;

    const [startDatetime, endDatetime] = getPeriodTimesByView({
      currentDate,
      currentView,
      localizer,
      timezone,
      moment,
    });
    const eventsOptions = {
      startDatetime: startDatetime.toISOString(),
      endDatetime: endDatetime.toISOString(),
      locationId: selectedLocation.id,
    };
    const newEvents = await BookingApi.fetchEventsForCalendar(
      eventsOptions,
      auth,
    );

    this.setState({
      events: initializeEvents(newEvents, moment),
    });
  }

  cancelAppointmentCallback(error, appointmentId) {
    const { handleDisplayFlashMessage } = this.props;
    if (error) {
      this.setState({
        isAppointmentDialogOpened: false,
        selectedEvent: null,
        clickedSlotDate: null,
        clickedSlotStaffId: '',
      });
      handleDisplayFlashMessage('Something went wrong', 'error');
      console.log('cancelAppointmentCallback_ERROR', error);
      return;
    }

    const { events } = this.state;
    const event = events.find(event => event.id === appointmentId);

    const idx = events.indexOf(event);
    const newEvents = [
      ...events.slice(0, idx),
      ...events.slice(idx + 1),
    ];

    this.setState({
      events: newEvents,
      isAppointmentDialogOpened: false,
      selectedEvent: null,
      clickedSlotDate: null,
      clickedSlotStaffId: '',
    });
    handleDisplayFlashMessage(
      'The appointment has been cancelled successfully',
    );
  }

  async setAllStaff(key) {
    const { selectedStaffId } = this.state;
    const { queryStaffId } = this.props;
    const staff = await this.getStaffForCalendar(key)
    const staffIndex = staff.findIndex(({ id }) => id === selectedStaffId);
    const newId = queryStaffId || ''
    this.setState({
      isAllStaff: key,
      isDesktopAllStaff: key,
      isAllRosterStaff: !key,
      staff,
      ...(
        staff.length === 1
          ? { rightArrowDisabled: true, leftArrowDisabled: true }
          : staffIndex === 0
            ? { leftArrowDisabled: true, rightArrowDisabled: false }
            : staffIndex === staff.length - 1
              ? { rightArrowDisabled: true, leftArrowDisabled: false }
              : staff.length > 1
                ? {rightArrowDisabled: false, leftArrowDisabled: false}
                : {}
      )
    }, () => {
      this.onClickStaff(newId, true)()
    });
  }

  async setAllRosterStaff(key) {
    const { queryStaffId } = this.props;
    const staff = await this.getStaffForCalendar(key)
    const preparedStaff = staff.filter((oneStaff) => oneStaff.dayShifts && oneStaff.dayShifts.some((shift) => isScheduled.includes(shift.type)));
    const newId = queryStaffId || ''

    const staffIndex = preparedStaff.findIndex(({ id }) => id === newId);
    this.setState({
      isAllStaff: key,
      isDesktopAllStaff: key,
      isAllRosterStaff: !key,
      staff: preparedStaff,
      ...(!newId ? { rightArrowDisabled: true, leftArrowDisabled: true, selectedStaffId: null, staff: [] } : {}),
      ...(
        preparedStaff.length === 1
          ? { rightArrowDisabled: true, leftArrowDisabled: true }
          : staffIndex === 0
            ? { leftArrowDisabled: true }
            : staffIndex === preparedStaff.length - 1
              ? { rightArrowDisabled: true }
              : {}
      )
    }, () => {
      this.onClickStaff(newId, false)()
    });
  }

  goToPrevStaff() {
    const { selectedStaffId, staff = [], rightArrowDisabled, isAllRosterStaff } = this.state;
    let preparedStaff = staff
    if (isAllRosterStaff) {
      preparedStaff = staff.filter((oneStaff) => oneStaff.dayShifts && oneStaff.dayShifts.some((shift) => isScheduled.includes(shift.type)));
    }
    const staffIndex = preparedStaff.findIndex(({ id }) => id === selectedStaffId);
    if (staffIndex - 1 === 0) {
      this.setState({ leftArrowDisabled: true });
    }
    if (rightArrowDisabled && preparedStaff.length > 1) {
      this.setState({ rightArrowDisabled: false });
    }
    const prevStaff = preparedStaff[staffIndex - 1];
    if (prevStaff) {
      this.onClickStaff(prevStaff.id)();
    }
  }

  goToNextStaff() {
    const { selectedStaffId, staff = [], leftArrowDisabled, isAllRosterStaff } = this.state;
    let preparedStaff = staff
    if (isAllRosterStaff) {
      preparedStaff = staff.filter((oneStaff) => oneStaff.dayShifts && oneStaff.dayShifts.some((shift) => isScheduled.includes(shift.type)));
    }
    const staffIndex = preparedStaff.findIndex(({ id }) => id === selectedStaffId);
    if (staffIndex + 1 === preparedStaff.length - 1) {
      this.setState({ rightArrowDisabled: true});
    }
    if (leftArrowDisabled && preparedStaff.length > 1) {
      this.setState({ leftArrowDisabled: false});
    }
    const nextStaff = preparedStaff[staffIndex + 1];
    if (nextStaff) {
      this.onClickStaff(nextStaff.id)();
    }
  }

  eventWithProps = props => {
    const {slotStep, currentView} = this.state;
    return (
      <Event {...props} slotStep={slotStep} isDay={currentView === 'day'} />
    )
  }

  render() {
    const {
      isAppointmentDialogOpened,
      isBusyTimeDialogOpened,
      events,
      currentView,
      currentDate,
      selectedLocation,
      selectedStaffId,
      changeAppointmentMode,
      staff,
      slotStep,
      slotsInOneHour,
      isAllRosterStaff,
      leftArrowDisabled,
      rightArrowDisabled
    } = this.state;
    const {
      classes,
      isLeftSidebarOpened,
      handleCloseLeftSidebar,
      ...restProps
    } = this.props;

    const min = getDateByMinutesOffset(
      currentDate,
      selectedLocation.beginTime,
      moment,
    );
    const max = getDateByMinutesOffset(
      currentDate,
      selectedLocation.endTime,
      moment,
    );

    let onSelectEvent = this.handleOpenEditEventDialog;
    if (changeAppointmentMode) {
      onSelectEvent = this.handleClickOnEventInChangeMode;
    }

    let onEventDrop = this.handleUpdateMovedEvent;
    if (changeAppointmentMode) {
      onEventDrop = this.handleMoveEventInChangeMode;
    }

    let onSelectSlot = this.onClickOpenPopover(slotStep);
    if (changeAppointmentMode) {
      onSelectSlot = this.handlePickTimeInChangeMode(slotStep);
    }

    const calendarOptions = {
      events,
      localizer,
      formats,
      min,
      max,
      isAllRosterStaff,
      selectedLocation,
      selectedStaffId,
      onSelectEvent,
      staff,
      showAllEvents: true,
      resizable: true,
      selectable: 'ignoreEvents',
      date: currentDate,
      defaultDate: moment().toDate(),
      view: currentView,
      defaultView: Views.DAY,
      views: { month: true, week: selectedStaffId === allRosteredStaffOption.value ? AllRosteredCalendarView : WeekView, day: DayView },
      components: {
        event: this.eventWithProps,
        toolbar: ToolBar,
        month: {
          event: MonthEvent,
        },
      },
      eventPropGetter: eventStyles,
      slotPropGetter: slotStyles({
        staff,
        max,
        moment,
        step: slotStep,
        location: selectedLocation,
      }),
      scrollToTime: 'currentTime',
      step: slotStep,
      timeslots: slotsInOneHour,
      onView: this.changeCurrentView,
      onNavigate: this.changeSelectedDatetime,
      onDrillDown: this.onClickMonthDate,
      dayLayoutAlgorithm: customLayoutAlgorithm,
      getRootNode: () => document.getElementById('__next'),
      tooltipAccessor: () => null, // Remove tooltip
      getNow: () => moment().toDate(),
    };

    let calendarContent;
    const isMonthView = currentView === Views.MONTH
    if (isMonthView) {
      calendarContent = (
        <MonthCalendar
          {...calendarOptions}
          onSelectSlot={(event) => this.onClickMonthDate(event.start, Views.DAY)}
        />
      );
    } else {
      calendarContent = (
        <CalendarWithPlugins
          {...calendarOptions}
          onSelectSlot={onSelectSlot}
          onEventDrop={onEventDrop}
          onEventResize={this.handleUpdateResizedEvent}
        />
      );
    }
    const currentStaff = staff.find(({ id }) => id === selectedStaffId);
    return (
      <div className={classes.root} data-testid="indexPage">
        {changeAppointmentMode && (
          <ChangeNavbar
            {...this.props}
            onApply={this.handleApplyChangingAppointment}
            onCancel={this.handleCancelChangingAppointment}
          />
        )}

        <Hidden lgUp>
          <Drawer
            variant="temporary"
            className={classes.drawer}
            anchor="left"
            classes={{
              paper: classes.drawerPaper,
            }}
            open={isLeftSidebarOpened}
            onClose={handleCloseLeftSidebar}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile
            }}
          >
            <SideBar
              {...this.state}
              {...restProps}
              isMobileBar
              allRosteredStaffOption={allRosteredStaffOption}
              onClickLocation={this.onClickLocation}
              onClickStaff={this.onClickStaff}
              setAllStaff={this.setAllStaff}
              setAllRosterStaff={this.setAllRosterStaff}
            />
          </Drawer>
        </Hidden>
        <Hidden lgDown>
          <Drawer
            open
            variant="permanent"
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile
            }}
          >
            <SideBar
              {...this.state}
              {...restProps}
              allRosteredStaffOption={allRosteredStaffOption}
              onClickLocation={this.onClickLocation}
              onClickStaff={this.onClickStaff}
            />
          </Drawer>
        </Hidden>

        <div className={classes.content}>
          {currentStaff && staff.length > 1 && !isMonthView && (
            <Hidden mdUp>
              <div className={classes.staffWrapper}>
                <div className={classes.staffContent}>
                  <Grid
                    container
                    onClick={this.goToPrevStaff}
                    className={cx(classes.arrowBox, leftArrowDisabled ? classes.arrowDisable : '')}
                  >
                    <ArrowForwardIcon
                      data-testid="calendarStaffBackButton"
                      className={cx(
                        classes.arrowIcon,
                        classes.leftArrowIcon,
                      )}
                    />
                  </Grid>
                  {/* <div
                    className={classes.dateTitle}
                  >
                    <div className={classes.dateTitleText}>
                      {currentStaff?.fullName || 'no staff selected'}
                    </div>
                  </div> */}
                  <Grid
                    container
                    onClick={this.goToNextStaff}
                    className={cx(classes.arrowBox, rightArrowDisabled ? classes.arrowDisable : '')}
                  >
                    <ArrowForwardIcon data-testid="calendarStaffNextButton" className={classes.arrowIcon} />
                  </Grid>
                </div>
              </div>
            </Hidden>
          )}
          <div className={classes.rootCalendarView}>
            {calendarContent}
          </div>

          <ClickMenu
            {...this.state}
            onClickClosePopover={this.onClickClosePopover}
            handleOpenNewAppointmentDialog={this.handleOpenNewAppointmentDialog}
            handleOpenNewBusyTimeDialog={this.handleOpenNewBusyTimeDialog}
          />

          <Hidden mdUp>
            <CustomDialog
              fullScreen
              maxWidth={false}
              scroll="paper"
              open={isAppointmentDialogOpened}
              classes={{
                paper: classes.appointmentPaper,
                paperFullScreen: classes.appointmentPaperFullScreen,
              }}
            >
              <AddAppointment
                {...this.state}
                {...restProps}
                staff={staff}
                handleCloseAppointmentDialog={this.handleCloseAppointmentDialog}
                onUpdateEvent={this.onUpdateEvent}
                cancelAppointmentCallback={this.cancelAppointmentCallback}
                handleStartChangingAppointment={this.handleStartChangingAppointment}
              />
            </CustomDialog>
          </Hidden>
          <Hidden mdDown>
            <CustomDialog
              maxWidth="lg"
              open={isAppointmentDialogOpened}
              classes={{
                paper: classes.appointmentPaper,
              }}
            >
              <AddAppointment
                {...this.state}
                {...restProps}
                staff={staff}
                handleCloseAppointmentDialog={this.handleCloseAppointmentDialog}
                onUpdateEvent={this.onUpdateEvent}
                cancelAppointmentCallback={this.cancelAppointmentCallback}
                handleStartChangingAppointment={this.handleStartChangingAppointment}
              />
            </CustomDialog>
          </Hidden>

          <Hidden smDown>
            <CustomDialog
              fullWidth
              maxWidth="xs"
              open={isBusyTimeDialogOpened}
            >
              <AddBusyTime
                {...this.state}
                {...this.props}
                onClose={this.handleCloseBusyTimeDialog}
                onUpdateEvent={this.onUpdateEvent}
              />
            </CustomDialog>
          </Hidden>
          <Hidden smUp>
            <CustomDialog
              fullScreen
              maxWidth={false}
              open={isBusyTimeDialogOpened}
            >
              <AddBusyTime
                {...this.state}
                {...this.props}
                onClose={this.handleCloseBusyTimeDialog}
                onUpdateEvent={this.onUpdateEvent}
              />
            </CustomDialog>
          </Hidden>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  business,
  locations,
  appointments,
  auth,
}) => ({
  business,
  locations,
  appointments,
  auth,
});

const mapDispatchToProps = (dispatch) => ({
  loadBusiness: bindActionCreators(loadBusiness, dispatch),
  loadLocationsForCalendar: bindActionCreators(
    loadLocationsForCalendar,
    dispatch,
  ),
  updateAppointment: bindActionCreators(updateAppointment, dispatch),
  loadCancellationReasons: bindActionCreators(loadCancellationReasons, dispatch),
  loadGetStartedStep: bindActionCreators(loadGetStartedStep, dispatch),
});

export const getServerSideProps = async ({ query }) => {
  const {
    date = '',
    view = '',
    location = '',
    staff = '',
  } = query;

  return {
    props: {
      queryDate: date,
      queryView: view,
      queryLocationId: location,
      queryStaffId: staff,
    },
  };
}

export default compose(
  withThemedLayoutAndSession,
  withMaxPageWidth('2000px'),
  connect(mapStateToProps, mapDispatchToProps),
)(withStyles(Index, styles));
